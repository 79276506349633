
// 
// @Author: Murrls 
.ui.table.primary-table {

    thead th {
        background-color: $bg-color;
        color: $default-nav-text-color;
        font-family: 'Quicksand-Bold';
        font-size: 14px;
    }
}

.ui.basic.table {
    background-color: $white;
}

// Style the table action columns
.ui.fixed.table td:last-child, .ui.fixed.table th:last-child {
    
    overflow: visible !important;
    &:empty {
        width: 5%;
    }
}

.pagination {

    .ui.inline.dropdown {
        margin-left: 5px;
    }

    .ui.inline.dropdown {
        margin-left: 5px;
    }

    .dropdown.icon {
        margin-left: 10px !important;
        color: $primary-color !important;
    }

    .page-nav {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        padding: 10px;
        margin: 0 0 0 auto;
        margin-right: 5px;
    }

    .primary-input {
        min-width: fit-content;
        float: left;
    }

}

.ui.loading.segment.table-loader {
	min-height: 90px !important;
}

.table-container {
    overflow-x: auto;
    margin-top: 15px;
    min-height: 160px;

    table {

        td,th {
            &.slim-col {
                @extend .trim-text;
            }
        }

        th.slim-col {
            width: 20%;
        }

        td.slim-col {
            max-width: 20px;
        }

        td {

            .ui.label {
                @extend .trim-text;
                max-width: 160px;
                @include border-radius($default-tag-border-radius !important);
                font-family: 'Quicksand-SemiBold';
            }
        }

        @include mq_small_down() {
            // resize headers
        }

        // thead {
        //     th {
        //         // @include 
        //         &:nth-child(2) {
        //             background-color: red !important;
        //             display: none;
        //         }
        //     }
        // }
    }
}


.content-section, .table-container {
    .table-loader-container {
        
        position: absolute;
        top: 15vh !important;

        &.loader {
            &::before, &::after {
              display: none;
            }
        }

        .rive-loader {
            canvas {
                width: 100px !important;
                height: 50px !important;
            }
            img {
                width: 50px !important;
                height: auto !important;
            }
        }
    }
}

// Experimenting with the GMail style tables
.gm-style-table {

}