.super-t {
    // @extend .primary-table;
    ---min-table-height: 250px;

    display: block;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    padding: 0;
    min-width: fit-content;
    min-height: var(---min-table-height);
    overflow: hidden;

    [class*="center aligned"] {
        justify-content: center;    
    }

    .batch-actions-dropdown {
        font-family: 'Quicksand-Medium';

        .menu [role="option"] {
            cursor: pointer;

            @include vertical-align();
            
            .text {
                margin-left: 10px;
                cursor: pointer;
            }
        }

    }

    tbody, thead {
        
        position: relative;
        display: block;

        .ui.inverted.dimmer {
            min-height: 100px;
        }

        tr {
            // padding-bottom: 10px;
            // padding-top: 10px;
            // -webkit-box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
            // box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            position: relative;

            td,th {
                -webkit-box-flex: 0 0 auto;
                -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
                // min-width: 50px;
                // flex-grow: 0;
                // flex-shrink: 0;
                // flex-basis: 50px;

                &.checkbox, &.image {
                    max-width: 40px;
                    min-width: 5%;
                    justify-content: start;
                }
                
                &:last-child {
                    max-width: 20px;
                    min-width: 2%;
                }

                &.read-count {
                    max-width: 10%;
                }
                
                &.channels-recipients {
                    // max-width: 10%;
                }

                &:nth-child(1) {
                    // background: none;
                    // border: none;
                    // -webkit-box-ordinal-group: -1;
                    // -webkit-order: -1;
                    // order: -1;
                    // -webkit-flex-basis: 168px;
                    // flex-basis: 168px;
                    // max-width: 168px;
                }

                &:nth-child(2) {
                    background: none;
                    // -webkit-box-ordinal-group: 0;
                    // -webkit-order: 0;
                    // order: 0;
                    padding: 0 10px 0 13px;
                    position: relative;
                    // width: 20px;
                }

                // .general {
                    -webkit-align-items: center;
                    align-items: center;
                    // border: none;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-flex: 100%;
                    -webkit-flex: 100%;
                    flex: 100%;
                    min-width: 120px;
                    // max-width: 120px;
                    line-height: 20px;
                    // -webkit-box-ordinal-group: 1;
                    // -webkit-order: 1;
                    // order: 1;
                // }

                &:nth-child(2) {
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: flex;
                    // -webkit-flex-basis: 168px;
                    // flex-basis: 168px;
                    max-width: 168px;
                    flex: 0 0 15%;
                }

                &.checkbox {
                    background: none;
                    -webkit-box-ordinal-group: 0;
                    -webkit-order: 0;
                    order: 0;
                    padding: 0 10px 0 13px !important;
                    position: relative;
                    width: 20px;
                    justify-content: start;
                }

                &.star {
                    padding: 0 10px 0 0;
                    -webkit-box-ordinal-group: 0;
                    -webkit-order: 0;
                    order: 0;
                    width: 20px;
                }

                // .general {
                    // padding-right: 6px;
                    // padding-left: 10px;
                // }

                // .general {
                    empty-cells: show;
                    font-size: .875rem;
                    vertical-align: middle;
                    white-space: nowrap;
                // }

                &.equal {
                    min-width: 20% !important;
                    &.actions {
                        max-width: 20px !important;
                        min-width: 20px !important;
                    }
                }

                span {
                    @extend .trim-text;
                }

                &:not(.sortable) {
                    span {
                        cursor: default;
                    }
                }
            }

            .actions {
                position: relative;
                .action-group {
                    position: absolute;
                    right: 10px;
                    // top: 5px;
                    display: none;

                    .button {
                        color: $primary-color !important;

                        &:not(:last-child) {
                            padding-right: 0;
                        }

                        &:hover {
                            background: transparent !important;
                            color: $secondary-color !important;
                        }
                    }

                    &:has(button.is-locked) {
                        display: inherit;
                        border: none;
                        .button {
                            &:not(.is-locked) {
                                display: none;
                            }

                            border: none !important;
                            box-shadow: none !important;
                            &:not(:hover) {
                                padding: 0;
                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &.no-data {
                td {
                    min-width: auto !important;
                    max-width: none !important;

                    justify-content: center;
                }
            }
        }
    }

    tbody.loading {
        min-height: 160px;
    }

    tbody tr {

        &[data-status="warning"] {
            td:first-child {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    background-color: $warning-color !important;
                    height: 100%;
                    width: 5px;
                    left: 0;
                    top: 0;
                }
            }
        }

        &.disabled {
            .image-icon {
                opacity: 0.5;
            }
        }

        &:hover {
            .hide-on-actions {
                // content: "";
                border-top: 1px solid rgb(34, 36, 38);
                opacity: 0.09;
                
                &:before {
                    content: "...";
                    position: relative;
                    left: 0;
                }
            }

            .action-group {
                display: inherit;
                background: color('primary', 'lighten-3');
                border: none;

                .button {
                    border: none;
                    padding: 0.78571429em 1.5em 0.78571429em;
                    span {
                        display: inline !important;
                    }
                }
            }
        }
    }

    tbody tr {
        &:last-child {
            border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        }
    }

    td, th {
        &.custom-icon {
            max-width: 50px;
            min-width: 10%;
        }
    }

    td {
        &.read-count {
            font-size: 18px;
            font-family: 'Quicksand-Bold';
        }
    }

    &__messages {

        &__detail {
            
        }
    }
    
    &__contacts {

        td,th {
            &.person {
                max-width: 250px;
                min-width: 10%;
            }
        }
    }
    
    &__contact_forms {

        td,th {
            &.description {
                max-width: 250px;
                min-width: 10%;
            }
        }
    }

    &__members {
        ---num-columns: 9;
        ---total-split: calc((var(---num-columns) - 2) / var(---num-columns) * 100%);

        td,th {
            // Training Wheels
            // border: 0.1px solid red;
            
            &:not(:first-child), &:not(:last-child), &:not(.hide-on-small-down) {
                max-width: var(---total-split) !important;
            }

            &:first-child, &:last-child {
                max-width: calc((var(---num-columns) - 7) / var(---num-columns) * 1px) !important;
            }

            &.has-app, &.parents, &.children, &.channels {
                // max-width: 80px;
                // min-width: 10%;
                
                // max-width: 168px;
                // flex: 0 0 15%;
            }
            
            // &.channels, &.tags {
            //     max-width: 50px;
            //     min-width: 10%;
            // }
        }

        .icon-container {
            gap: 5px;
            @include vertical-align();
            cursor: pointer;
            
            .material-icons, .counter {
                color: color('primary', 'base');
            }

            .counter {
                font-size: 0.9rem;
            }
        }

        .is_member {
            .material-icons {
                color: color('grey', 'base') !important;
            }
        }
    }
    
    &__calendar_events {
        ---num-columns: 8;
        ---total-split: calc((var(---num-columns) - 2) / var(---num-columns) * 100%);

        td,th {
            // Training Wheels
            // border: 0.1px solid red;
            
            &:not(:first-child), &:not(:last-child), &:not(.hide-on-small-down) {
                max-width: var(---total-split) !important;
            }

            &:first-child, &:last-child {
                max-width: calc((var(---num-columns) - 7) / var(---num-columns) * 1px) !important;
            }

            &.has-app, &.parents, &.children, &.channels {
                // max-width: 80px;
                // min-width: 10%;
                
                // max-width: 168px;
                // flex: 0 0 15%;
            }
            
            &.event-name {
                // max-width: 50px;
                min-width: 25%;
            }
            
            &.channels {
                max-width: 50px;
                min-width: 10%;
            }
        }

        .is_member, .is_multiday, .is_repeat {
            .large.icon.check.circle {
                color: color('green', 'base');
            }
        }

        .icon-container {
            gap: 5px;
            @include vertical-align();
            cursor: pointer;
            
            .material-icons, .counter {
                color: color('primary', 'base');
            }

            .counter {
                font-size: 0.9rem;
            }
        }
    }
    
    &__channels {
        td,th {
            
            &.channel-name {
                max-width: 100% !important;
                flex: 100% !important;
            }
            
            &.custom-icon {
                max-width: 50px;
                min-width: 12%;
            }
        }
    }
    
    &__calendars {
        ---num-columns: 6;
        ---total-split: calc((var(---num-columns) - 2) / var(---num-columns) * 100%);

        td,th {

            &:nth-child(2) {
                min-width: 25%;
            }

            &.image {
                max-width: 80px;
                min-width: 10%;
                justify-content: center !important;
            }
            
            .image {
                &.ui.fluid {
                    width: 30px;
                }
            }
            
            // &.channels {
            //     max-width: 50px;
            //     min-width: 10%;
            // }
        }

        .is_member, .is_multiday, .is_repeat {
            .large.icon.check.circle {
                color: color('green', 'base');
            }
        }

        .icon-container {
            gap: 5px;
            @include vertical-align();
            cursor: pointer;
            
            .material-icons, .counter {
                color: color('primary', 'base');
            }

            .counter {
                font-size: 0.9rem;
            }
        }
    }

    &__messages__detail {
        .answered-, .paid- {
            &tag {
                // color: black !important;
                background-color: color("green", "lighten-3") !important;
            }
        }

        .distribution-status {
            .ui.label {
                min-width: fit-content;
            }
        }
    }
    
    .add-individuals &__members {
        tbody {
            max-height: 50vh;
            overflow: hidden scroll;
        }
    }

    &__member_history__detail {
        td,th {
            
            &:nth-child(1) {
                max-width: 15% !important;
            }
            
            &.action-html {
                width: 1rem;
                display: inline;
                white-space: break-spaces;
            }
            
            &.source_formatted, &.action, &.author {
                max-width: 80px !important;
            }
        }

        .history-action-text {
            display: grid;
            gap: 0.9rem;

            span {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                flex-wrap: wrap;
            }
        }
        
        .person-tag {
            background-color: color("secondary", "base") !important;
            color: $default-text-color !important;
        }
    }
}

.table-container {
    
    &.message-detail.excluded-members {
        height: 60vh;
    }
    
    &.message-detail {
        min-height: 300px;
    }

    .ui.dimmer {
        z-index: 10;
    }
    
    .selection-indicator {
        background-color: transparent !important;

        &.all-link {
            color: color("blue", "base");
            &:hover {
                cursor: pointer;
                background-color: color("blue", "lighten-4") !important;
            }
        }
    }

    .all-selection {
        background-color: $bg-color;
        @include border-radius(5px);
    }

    tr[data-disabled="true"] {
        background-color: color("grey", "lighten-4") !important;
        opacity: 0.6;
    }

    .child {
        .checkbox input[type="checkbox"]{
            visibility: hidden;
        }
        .checkbox {
            position: relative;
            &::after {
                content: "↳";
                position: relative;
                left: 0;
            }
        }

        background-color: color("grey", "lighten-4") !important;
        // &:hover {
        //     cursor: pointer;
        //     background-color: color("primary", "lighten-3") !important;
        // }

        &.last {
            &:not(:last-child) {
                border-bottom: 5px solid color("grey", "lighten-2") !important;
            }
        }
    }

    .content-w-subtext {
        display: inline-block;
        vertical-align: top;
        .sub.header {
            display: block;
            font-weight: 400;
            padding: 0;
            margin: 0;
            // font-size: 1rem;
            line-height: 1.2em;
            color: color("grey", "lighten-1");
        }
    }
}

.pagination {
    .disabled {
        @extend .no-select;
    }

    .page-nav {
        align-items: center;
        padding: 0;
        .primary-input {
            margin-right: 20px;
            padding: 5px;
            border-color: color('grey', 'lighten-1');
        }

        .pagers {
            height: 30px;
            @include vertical-align();
            font-family: 'Quicksand-SemiBold';

            .page-descriptor {
                float: left;
                margin: 0;

                &.channels {
                    margin-top: 10;
                }
            }

            .left.chevron {
                float: left;
                cursor: pointer;
            }
            
            .right.chevron {
                cursor: pointer;
            }

            i {
                @include vertical-align();
                &.left {
                    margin-right: 30px;
                    margin-left: 20px;
                }
            }
        }
    }
}

.pagination-container {
    .filter-container {
        width: 40px !important;
    }

    .pagers-section {
        border-top: 1px solid color('grey', 'lighten-2');
    }

    .icon {
        &.chevron, &.ellipsis.vertical {
            &:hover {
                color: $secondary-color;
            }
        }
    }

    .no-content {
        border: 1px solid red;
    }

    .checkbox-actions {
        display: flex;

        .input-container.theme-color {
            height: 100%;
            label.label {
                padding: 0;
            }
        }
    }

    .filter-by-selected {
        padding: 0.78571429em 1.5em 0.78571429em;
    }

    .action {
        &___buttons, &___pagination {
            display: flex !important;
            align-items: flex-end;

            .tertiary {
                &.disabled {
                    color: color('grey', 'base') !important;
                }
            }
        }
    }
}