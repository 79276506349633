@use "sass:color";
@use "sass:map";
// COLORS
$primary: (
  "lighten-4":  color.adjust(#2A7EE2, $lightness: 20%),
  "lighten-3":  color.adjust(#2A7EE2, $lightness: 40%),
  "lighten-2":  color.adjust(#2A7EE2, $lightness: 60%),
  "lighten-1":  color.adjust(#2A7EE2, $lightness: 80%),
  "base":       #2A7EE2,
  "darken-1":   color.adjust(#2A7EE2, $lightness: -10%),
  "darken-2":   color.adjust(#2A7EE2, $lightness: -20%),
  "darken-3":   color.adjust(#2A7EE2, $lightness: -30%),
  "darken-4":   color.adjust(#2A7EE2, $lightness: -40%),
  "darken-5":   color.adjust(#2A7EE2, $lightness: -50%),
);

$secondary: (
  "lighten-4":  color.adjust(#EDAC02, $lightness: 20%),
  "lighten-3":  color.adjust(#EDAC02, $lightness: 40%),
  "lighten-2":  color.adjust(#EDAC02, $lightness: 60%),
  "lighten-1":  color.adjust(#EDAC02, $lightness: 80%),
  "base":       #EDAC02,
  "darken-1":   color.adjust(#EDAC02, $lightness: -10%),
  "darken-2":   color.adjust(#EDAC02, $lightness: -20%),
  "darken-3":   color.adjust(#EDAC02, $lightness: -30%),
  "darken-4":   color.adjust(#EDAC02, $lightness: -40%),
  "darken-5":   color.adjust(#EDAC02, $lightness: -50%),
);

$tertiary-green: (
  "lighten-5":  color.adjust(#A7C957, $lightness: 25%),
  "lighten-4":  color.adjust(#A7C957, $lightness: 20%),
  "lighten-3":  color.adjust(#A7C957, $lightness: 40%),
  "lighten-2":  color.adjust(#A7C957, $lightness: 60%),
  "lighten-1":  color.adjust(#A7C957, $lightness: 80%),
  "base":       #A7C957,
  "darken-1":   color.adjust(#A7C957, $lightness: -10%),
  "darken-2":   color.adjust(#A7C957, $lightness: -20%),
  "darken-3":   color.adjust(#A7C957, $lightness: -30%),
  "darken-4":   color.adjust(#A7C957, $lightness: -40%),
  "darken-5":   color.adjust(#A7C957, $lightness: -50%),
);

$tertiary-teal: (
  "lighten-4":  color.adjust(#02B4A9, $lightness: 20%),
  "lighten-3":  color.adjust(#02B4A9, $lightness: 40%),
  "lighten-2":  color.adjust(#02B4A9, $lightness: 60%),
  "lighten-1":  color.adjust(#02B4A9, $lightness: 80%),
  "base":       #02B4A9,
  "darken-1":   color.adjust(#02B4A9, $lightness: -10%),
  "darken-2":   color.adjust(#02B4A9, $lightness: -20%),
  "darken-3":   color.adjust(#02B4A9, $lightness: -30%),
  "darken-4":   color.adjust(#02B4A9, $lightness: -40%),
  "darken-5":   color.adjust(#02B4A9, $lightness: -50%),
);

$red: (
  "lighten-5":  #FFEBEE,
  "lighten-4":  #FFCDD2,
  "lighten-3":  #EF9A9A,
  "lighten-2":  #E57373,
  "lighten-1":  #EF5350,
  "base":       #F44336,
  "darken-1":   #E53935,
  "darken-2":   #D32F2F,
  "darken-3":   #C62828,
  "darken-4":   #B71C1C,
  "accent-1":    #FF8A80,
  "accent-2":    #FF5252,
  "accent-3":    #FF1744,
  "accent-4":    #D50000
);

$pink: (
  "lighten-5":  #fce4ec,
  "lighten-4":  #f8bbd0,
  "lighten-3":  #f48fb1,
  "lighten-2":  #f06292,
  "lighten-1":  #ec407a,
  "base":       #e91e63,
  "darken-1":   #d81b60,
  "darken-2":   #c2185b,
  "darken-3":   #ad1457,
  "darken-4":   #880e4f,
  "accent-1":    #ff80ab,
  "accent-2":    #ff4081,
  "accent-3":    #f50057,
  "accent-4":    #c51162
);

$purple: (
  "lighten-5":  #f3e5f5,
  "lighten-4":  #e1bee7,
  "lighten-3":  #ce93d8,
  "lighten-2":  #ba68c8,
  "lighten-1":  #ab47bc,
  "base":       #9c27b0,
  "darken-1":   #8e24aa,
  "darken-2":   #7b1fa2,
  "darken-3":   #6a1b9a,
  "darken-4":   #4a148c,
  "accent-1":    #ea80fc,
  "accent-2":    #e040fb,
  "accent-3":    #d500f9,
  "accent-4":    #aa00ff
);

$deep-purple: (
  "lighten-5":  #ede7f6,
  "lighten-4":  #d1c4e9,
  "lighten-3":  #b39ddb,
  "lighten-2":  #9575cd,
  "lighten-1":  #7e57c2,
  "base":       #673ab7,
  "darken-1":   #5e35b1,
  "darken-2":   #512da8,
  "darken-3":   #4527a0,
  "darken-4":   #311b92,
  "accent-1":    #b388ff,
  "accent-2":    #7c4dff,
  "accent-3":    #651fff,
  "accent-4":    #6200ea
);

$indigo: (
  "lighten-5":  #e8eaf6,
  "lighten-4":  #c5cae9,
  "lighten-3":  #9fa8da,
  "lighten-2":  #7986cb,
  "lighten-1":  #5c6bc0,
  "base":       #3f51b5,
  "darken-1":   #3949ab,
  "darken-2":   #303f9f,
  "darken-3":   #283593,
  "darken-4":   #1a237e,
  "accent-1":    #8c9eff,
  "accent-2":    #536dfe,
  "accent-3":    #3d5afe,
  "accent-4":    #304ffe
);

$blue: (
  "lighten-5":  #E3F2FD,
  "lighten-4":  #BBDEFB,
  "lighten-3":  #90CAF9,
  "lighten-2":  #64B5F6,
  "lighten-1":  #42A5F5,
  "base":       #2196F3,
  "darken-1":   #1E88E5,
  "darken-2":   #1976D2,
  "darken-3":   #1565C0,
  "darken-4":   #0D47A1,
  "accent-1":    #82B1FF,
  "accent-2":    #448AFF,
  "accent-3":    #2979FF,
  "accent-4":    #2962FF
);

$light-blue: (
  "lighten-5":  #e1f5fe,
  "lighten-4":  #b3e5fc,
  "lighten-3":  #81d4fa,
  "lighten-2":  #4fc3f7,
  "lighten-1":  #29b6f6,
  "base":       #03a9f4,
  "darken-1":   #039be5,
  "darken-2":   #0288d1,
  "darken-3":   #0277bd,
  "darken-4":   #01579b,
  "accent-1":    #80d8ff,
  "accent-2":    #40c4ff,
  "accent-3":    #00b0ff,
  "accent-4":    #0091ea
);

$cyan: (
  "lighten-5":  #e0f7fa,
  "lighten-4":  #b2ebf2,
  "lighten-3":  #80deea,
  "lighten-2":  #4dd0e1,
  "lighten-1":  #26c6da,
  "base":       #00bcd4,
  "darken-1":   #00acc1,
  "darken-2":   #0097a7,
  "darken-3":   #00838f,
  "darken-4":   #006064,
  "accent-1":    #84ffff,
  "accent-2":    #18ffff,
  "accent-3":    #00e5ff,
  "accent-4":    #00b8d4
);

$teal: (
  "lighten-5":  #e0f2f1,
  "lighten-4":  #b2dfdb,
  "lighten-3":  #80cbc4,
  "lighten-2":  #4db6ac,
  "lighten-1":  #26a69a,
  "base":       #009688,
  "darken-1":   #00897b,
  "darken-2":   #00796b,
  "darken-3":   #00695c,
  "darken-4":   #004d40,
  "accent-1":    #a7ffeb,
  "accent-2":    #64ffda,
  "accent-3":    #1de9b6,
  "accent-4":    #00bfa5
);

$green: (
  "lighten-5":  #E8F5E9,
  "lighten-4":  #C8E6C9,
  "lighten-3":  #A5D6A7,
  "lighten-2":  #81C784,
  "lighten-1":  #66BB6A,
  "base":       #4CAF50,
  "darken-1":   #43A047,
  "darken-2":   #388E3C,
  "darken-3":   #2E7D32,
  "darken-4":   #1B5E20,
  "accent-1":    #B9F6CA,
  "accent-2":    #69F0AE,
  "accent-3":    #00E676,
  "accent-4":    #00C853
);

$light-green: (
  "lighten-5":  #f1f8e9,
  "lighten-4":  #dcedc8,
  "lighten-3":  #c5e1a5,
  "lighten-2":  #aed581,
  "lighten-1":  #9ccc65,
  "base":       #8bc34a,
  "darken-1":   #7cb342,
  "darken-2":   #689f38,
  "darken-3":   #558b2f,
  "darken-4":   #33691e,
  "accent-1":    #ccff90,
  "accent-2":    #b2ff59,
  "accent-3":    #76ff03,
  "accent-4":    #64dd17
);

$lime: (
  "lighten-5":  #f9fbe7,
  "lighten-4":  #f0f4c3,
  "lighten-3":  #e6ee9c,
  "lighten-2":  #dce775,
  "lighten-1":  #d4e157,
  "base":       #cddc39,
  "darken-1":   #c0ca33,
  "darken-2":   #afb42b,
  "darken-3":   #9e9d24,
  "darken-4":   #827717,
  "accent-1":    #f4ff81,
  "accent-2":    #eeff41,
  "accent-3":    #c6ff00,
  "accent-4":    #aeea00
);

$yellow: (
  "lighten-5":  #fffde7,
  "lighten-4":  #fff9c4,
  "lighten-3":  #fff59d,
  "lighten-2":  #fff176,
  "lighten-1":  #ffee58,
  "base":       #ffeb3b,
  "darken-1":   #fdd835,
  "darken-2":   #fbc02d,
  "darken-3":   #f9a825,
  "darken-4":   #f57f17,
  "accent-1":    #ffff8d,
  "accent-2":    #ffff00,
  "accent-3":    #ffea00,
  "accent-4":    #ffd600
);

$amber: (
  "lighten-5":  #fff8e1,
  "lighten-4":  #ffecb3,
  "lighten-3":  #ffe082,
  "lighten-2":  #ffd54f,
  "lighten-1":  #ffca28,
  "base":       #ffc107,
  "darken-1":   #ffb300,
  "darken-2":   #ffa000,
  "darken-3":   #ff8f00,
  "darken-4":   #ff6f00,
  "accent-1":    #ffe57f,
  "accent-2":    #ffd740,
  "accent-3":    #ffc400,
  "accent-4":    #ffab00
);

$orange: (
  "lighten-5":  #fff3e0,
  "lighten-4":  #ffe0b2,
  "lighten-3":  #ffcc80,
  "lighten-2":  #ffb74d,
  "lighten-1":  #ffa726,
  "base":       #ff9800,
  "darken-1":   #fb8c00,
  "darken-2":   #f57c00,
  "darken-3":   #ef6c00,
  "darken-4":   #e65100,
  "accent-1":    #ffd180,
  "accent-2":    #ffab40,
  "accent-3":    #ff9100,
  "accent-4":    #ff6d00
);

$deep-orange: (
  "lighten-5":  #fbe9e7,
  "lighten-4":  #ffccbc,
  "lighten-3":  #ffab91,
  "lighten-2":  #ff8a65,
  "lighten-1":  #ff7043,
  "base":       #ff5722,
  "darken-1":   #f4511e,
  "darken-2":   #e64a19,
  "darken-3":   #d84315,
  "darken-4":   #bf360c,
  "accent-1":    #ff9e80,
  "accent-2":    #ff6e40,
  "accent-3":    #ff3d00,
  "accent-4":    #dd2c00
);

$brown: (
  "lighten-5":  #efebe9,
  "lighten-4":  #d7ccc8,
  "lighten-3":  #bcaaa4,
  "lighten-2":  #a1887f,
  "lighten-1":  #8d6e63,
  "base":       #795548,
  "darken-1":   #6d4c41,
  "darken-2":   #5d4037,
  "darken-3":   #4e342e,
  "darken-4":   #3e2723
);

$blue-grey: (
  "lighten-5":  #eceff1,
  "lighten-4":  #cfd8dc,
  "lighten-3":  #b0bec5,
  "lighten-2":  #90a4ae,
  "lighten-1":  #78909c,
  "base":       #607d8b,
  "darken-1":   #546e7a,
  "darken-2":   #455a64,
  "darken-3":   #37474f,
  "darken-4":   #263238
);

$grey: (
  "lighten-5":  #fafafa,
  "lighten-4":  #f5f5f5,
  "lighten-3":  #eeeeee,
  "lighten-2":  #e0e0e0,
  "lighten-1":  #bdbdbd,
  "base":       #9e9e9e,
  "darken-1":   #757575,
  "darken-2":   #616161,
  "darken-3":   #424242,
  "darken-4":   #212121
);

$shades: (
  "black":        #000000,
  "white":        #FFFFFF,
  "transparent":  transparent
);

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary-green": $tertiary-green,
  "tertiary-teal": $tertiary-teal,
  "red": $red,
  "pink": $pink,
  "purple": $purple,
  "deep-purple": $deep-purple,
  "indigo": $indigo,
  "blue": $blue,
  "light-blue": $light-blue,
  "cyan": $cyan,
  "teal": $teal,
  "green": $green,
  "light-green": $light-green,
  "lime": $lime,
  "yellow": $yellow,
  "amber": $amber,
  "orange": $orange,
  "deep-orange": $deep-orange,
  "brown": $brown,
  "blue-grey": $blue-grey,
  "grey": $grey,
  "shades": $shades
);

// Color Classes
$enable_full_palette: true;

@if $enable_full_palette == true {
  @each $color_name,
  $color in $colors {
      @each $color_type,
      $color_value in $color {
          @if $color_type=="base" {
              .#{$color_name} {
                  background-color: $color_value !important;
              }
              .#{$color_name}-text {
                  color: $color_value !important;
              }
          }
          @else {
              .#{$color_name}.#{$color_type} {
                  background-color: $color_value !important;
              }
              // .#{$color_name}-text.text-#{$color_type} {
              //     color: $color_value !important;
              // }
          }
      }
  }
}

// Shade classes
@each $color,
$color_value in $shades {
    .#{$color} {
        background-color: $color_value !important;
    }
    .#{$color}-text {
        color: $color_value !important;
    }
}

// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)
@function color($color, $type) {
    @if map.has-key($colors, $color) {
        $curr_color: map.get($colors, $color);
        @if map.has-key($curr_color, $type) {
            @return map.get($curr_color, $type);
        }
    }
    // @warn "Unknown `#{name}` in $colors.";
    @return null;
}

.light-blue-text {
    color: map.get($blue, "accent-1") !important;
}

.dark-grey-text {
    color:#4f4f4f !important;
}


// Material Design Colors
$danger-color: #ff3547;
.danger-color {
    background-color: $danger-color!important;
}

$danger-color-dark: #CC0000;
.danger-color-dark {
    background-color: $danger-color-dark!important;
}

$warning-color: #ffbb33;
.warning-color {
    background-color: $warning-color!important;
}

$warning-color-dark: #FF8800;
.warning-color-dark {
    background-color: $warning-color-dark!important;
}

$success-color: #00C851;
.success-color {
    background-color: $success-color!important;
}

$success-color-dark: #007E33;
.success-color-dark {
    background-color: $success-color-dark!important;
}

$info-color: #33b5e5;
.info-color {
    background-color: $info-color!important;
}

$info-color-dark: #0099CC;
.info-color-dark {
    background-color: $info-color-dark!important;
}

// MDB Colors
$default-color: #2BBBAD;
.default-color {
    background-color: $default-color!important;
}

$default-color-dark: #00695c;
.default-color-dark {
    background-color: $default-color-dark!important;
}

$primary-color: #2A7EE2;
.primary-color {
    background-color: $primary-color!important;
}
.primary-color-text {
    color: $primary-color!important;
}

$primary-color-dark: color.change($color: $primary-color, $lightness: 15%) !default;
.primary-color-dark {
    background-color: $primary-color-dark!important;
}
.primary-color-dark-text {
    color: $primary-color-dark!important;
}

$secondary-color: #EDAC02;
.secondary-color {
    background-color: $secondary-color!important;

    &-text {
        color: $secondary-color!important;
    }    
}


$secondary-color-dark: color.adjust($secondary-color, $lightness: -50%);
.secondary-color-dark {
    background-color: $secondary-color-dark!important;
    
    &-text {
        color: $secondary-color-dark!important;
    }
}

$highlight-color: #F8F5F1;

$elegant-color: #2E2E2E;
.elegant-color {
    background-color: $elegant-color!important;
}

$elegant-color-dark: #212121;
.elegant-color-dark {
    background-color: $elegant-color-dark!important;
}

$stylish-color: #4B515D;
.stylish-color {
    background-color: $stylish-color!important;
}

$stylish-color-dark: #3E4551;
.stylish-color-dark {
    background-color: $stylish-color-dark!important;
}

$special-color: #37474F;
.special-color {
    background-color: $special-color!important;
}

$special-color-dark: #263238;
.special-color-dark {
    background-color: $special-color-dark!important;
}

$unique-color: #3F729B;
.unique-color {
    background-color: $unique-color!important;
}

$unique-color-dark: #1C2331;
.unique-color-dark {
    background-color: $unique-color-dark!important;
}

//$mdb-color: #45526E;
.mdb-color {
    background-color: #45526E!important;
}

$error-color: color("red", "base") !default;

// RGBA colors
// Light overlays
$rgba-blue-light: rgba(3, 169, 244, 0.3);
.rgba-blue-light {
    background-color: $rgba-blue-light!important;
}

$rgba-red-light: rgba(244, 67, 54, 0.3);
.rgba-red-light {
    background-color: $rgba-red-light!important;
}

$rgba-pink-light: rgba(233, 30, 99, 0.3);
.rgba-pink-light {
    background-color: $rgba-pink-light!important;
}

$rgba-purple-light: rgba(156, 39, 176, 0.3);
.rgba-purple-light {
    background-color: $rgba-purple-light!important;
}

$rgba-indigo-light: rgba(63, 81, 181, 0.3);
.rgba-indigo-light {
    background-color: $rgba-indigo-light!important;
}

$rgba-cyan-light: rgba(0, 188, 212, 0.3);
.rgba-cyan-light {
    background-color: $rgba-cyan-light!important;
}

$rgba-teal-light: rgba(0, 150, 136, 0.3);
.rgba-teal-light {
    background-color: $rgba-teal-light!important;
}

$rgba-green-light: rgba(76, 175, 80, 0.3);
.rgba-green-light {
    background-color: $rgba-green-light!important;
}

$rgba-lime-light: rgba(205, 220, 57, 0.3);
.rgba-lime-light {
    background-color: $rgba-lime-light!important;
}

$rgba-yellow-light: rgba(255, 235, 59, 0.3);
.rgba-yellow-light {
    background-color: $rgba-yellow-light!important;
}

$rgba-orange-light: rgba(255, 152, 0, 0.3);
.rgba-orange-light {
    background-color: $rgba-orange-light!important;
}

$rgba-brown-light: rgba(121, 85, 72, 0.3);
.rgba-brown-light {
    background-color: $rgba-brown-light!important;
}

$rgba-grey-light: rgba(158, 158, 158, 0.3);
.rgba-grey-light {
    background-color: $rgba-grey-light!important;
}

$rgba-bluegrey-light: rgba(96, 125, 139, 0.3);
.rgba-bluegrey-light {
    background-color: $rgba-bluegrey-light!important;
}

$rgba-black-light: rgba(0, 0, 0, 0.3);
.rgba-black-light {
    background-color: $rgba-black-light!important;
}

$rgba-stylish-light: rgba(62, 69, 81, 0.3);
.rgba-stylish-light {
    background-color: $rgba-stylish-light!important;
}

$rgba-white-light: rgba(255, 255, 255, 0.3);
.rgba-white-light {
    background-color: $rgba-white-light!important;
}

// Strong overlays
$rgba-blue-strong: rgba(3, 169, 244, 0.7);
.rgba-blue-strong {
    background-color: $rgba-blue-strong!important;
}

$rgba-red-strong: rgba(244, 67, 54, 0.7);
.rgba-red-strong {
    background-color: $rgba-red-strong!important;
}

$rgba-pink-strong: rgba(233, 30, 99, 0.7);
.rgba-pink-strong {
    background-color: $rgba-pink-strong!important;
}

$rgba-purple-strong: rgba(156, 39, 176, 0.7);
.rgba-purple-strong {
    background-color: $rgba-purple-strong!important;
}

$rgba-indigo-strong: rgba(63, 81, 181, 0.7);
.rgba-indigo-strong {
    background-color: $rgba-indigo-strong!important;
}

$rgba-cyan-strong: rgba(0, 188, 212, 0.7);
.rgba-cyan-strong {
    background-color: $rgba-cyan-strong!important;
}

$rgba-teal-strong: rgba(0, 150, 136, 0.7);
.rgba-teal-strong {
    background-color: $rgba-teal-strong!important;
}

$rgba-green-strong: rgba(76, 175, 80, 0.7);
.rgba-green-strong {
    background-color: $rgba-green-strong!important;
}

$rgba-lime-strong: rgba(205, 220, 57, 0.7);
.rgba-lime-strong {
    background-color: $rgba-lime-strong!important;
}

$rgba-yellow-strong: rgba(255, 235, 59, 0.7);
.rgba-yellow-strong {
    background-color: $rgba-yellow-strong!important;
}

$rgba-orange-strong: rgba(255, 152, 0, 0.7);
.rgba-orange-strong {
    background-color: $rgba-orange-strong!important;
}

$rgba-brown-strong: rgba(121, 85, 72, 0.7);
.rgba-brown-strong {
    background-color: $rgba-brown-strong!important;
}

$rgba-grey-strong: rgba(158, 158, 158, 0.7);
.rgba-grey-strong {
    background-color: $rgba-grey-strong!important;
}

$rgba-bluegrey-strong: rgba(96, 125, 139, 0.7);
.rgba-bluegrey-strong {
    background-color: $rgba-bluegrey-strong!important;
}

$rgba-black-strong: rgba(0, 0, 0, 0.7);
.rgba-black-strong {
    background-color: $rgba-black-strong!important;
}

$rgba-stylish-strong: rgba(62, 69, 81, 0.7);
.rgba-stylish-strong {
    background-color: $rgba-stylish-strong!important;
}

$rgba-white-strong: rgba(255, 255, 255, 0.7);
.rgba-white-strong {
    background-color: $rgba-white-strong!important;
}

// Super light overlays
$rgba-blue-slight: rgba(3, 169, 244, 0.1);
.rgba-blue-slight {
    background-color: $rgba-blue-slight!important;
}

$rgba-red-slight: rgba(244, 67, 54, 0.1);
.rgba-red-slight {
    background-color: $rgba-red-slight!important;
}

$rgba-pink-slight: rgba(233, 30, 99, 0.1);
.rgba-pink-slight {
    background-color: $rgba-pink-slight!important;
}

$rgba-purple-slight: rgba(156, 39, 176, 0.1);
.rgba-purple-slight {
    background-color: $rgba-purple-slight!important;
}

$rgba-indigo-slight: rgba(63, 81, 181, 0.1);
.rgba-indigo-slight {
    background-color: $rgba-indigo-slight!important;
}

$rgba-cyan-slight: rgba(0, 188, 212, 0.1);
.rgba-cyan-slight {
    background-color: $rgba-cyan-slight!important;
}

$rgba-teal-slight: rgba(0, 150, 136, 0.1);
.rgba-teal-slight {
    background-color: $rgba-teal-slight!important;
}

$rgba-green-slight: rgba(76, 175, 80, 0.1);
.rgba-green-slight {
    background-color: $rgba-green-slight!important;
}

$rgba-lime-slight: rgba(205, 220, 57, 0.1);
.rgba-lime-slight {
    background-color: $rgba-lime-slight!important;
}

$rgba-yellow-slight: rgba(255, 235, 59, 0.1);
.rgba-yellow-slight {
    background-color: $rgba-yellow-slight!important;
}

$rgba-orange-slight: rgba(255, 152, 0, 0.1);
.rgba-orange-slight {
    background-color: $rgba-orange-slight!important;
}

$rgba-brown-slight: rgba(121, 85, 72, 0.1);
.rgba-brown-slight {
    background-color: $rgba-brown-slight!important;
}

$rgba-grey-slight: rgba(158, 158, 158, 0.1);
.rgba-grey-slight {
    background-color: $rgba-grey-slight!important;
}

$rgba-bluegrey-slight: rgba(96, 125, 139, 0.1);
.rgba-bluegrey-slight {
    background-color: rgba(96, 125, 139, 0.1)!important;
}

$rgba-black-slight: rgba(0, 0, 0, 0.1);
.rgba-black-slight {
    background-color: $rgba-black-slight!important;
}

$rgba-stylish-slight: rgba(62, 69, 81, 0.1);
.rgba-stylish-slight {
    background-color: $rgba-stylish-slight!important;
}

$rgba-white-slight: rgba(255, 255, 255, 0.1);
.rgba-white-slight {
    background-color: $rgba-white-slight!important;
}

$rgba-colors: (
    // Super light
    "blue-slight": $rgba-blue-slight,
    "red-slight": $rgba-red-slight,
    "pink-slight": $rgba-pink-slight,
    "purple-slight": $rgba-purple-slight,
    "indigo-slight": $rgba-indigo-slight,
    "cyan-slight": $rgba-cyan-slight,
    "teal-slight": $rgba-teal-slight,
    "green-slight": $rgba-green-slight,
    "lime-slight": $rgba-lime-slight,
    "yellow-slight": $rgba-yellow-slight,
    "orange-slight": $rgba-orange-slight,
    "brown-slight": $rgba-brown-slight,
    "grey-slight": $rgba-grey-slight,
    "bluegrey-slight": $rgba-bluegrey-slight,
    "black-slight": $rgba-black-slight,
    "stylish-slight": $rgba-stylish-slight,
    "white-slight": $rgba-white-slight,
    // Light
    "blue-light": $rgba-blue-light,
    "red-light": $rgba-red-light,
    "pink-light": $rgba-pink-light,
    "purple-light": $rgba-purple-light,
    "indigo-light": $rgba-indigo-light,
    "cyan-light": $rgba-cyan-light,
    "teal-light": $rgba-teal-light,
    "green-light": $rgba-green-light,
    "lime-light": $rgba-lime-light,
    "yellow-light": $rgba-yellow-light,
    "orange-light": $rgba-orange-light,
    "brown-light": $rgba-brown-light,
    "grey-light": $rgba-grey-light,
    "bluegrey-light": $rgba-bluegrey-light,
    "black-light": $rgba-black-light,
    "stylish-light": $rgba-stylish-light,
    "white-light": $rgba-white-light,
    // Strong
    "blue-strong": $rgba-blue-strong,
    "red-strong": $rgba-red-strong,
    "pink-strong": $rgba-pink-strong,
    "purple-strong": $rgba-purple-strong,
    "indigo-strong": $rgba-indigo-strong,
    "cyan-strong": $rgba-cyan-strong,
    "teal-strong": $rgba-teal-strong,
    "green-strong": $rgba-green-strong,
    "lime-strong": $rgba-lime-strong,
    "yellow-strong": $rgba-yellow-strong,
    "orange-strong": $rgba-orange-strong,
    "brown-strong": $rgba-brown-strong,
    "grey-strong": $rgba-grey-strong,
    "bluegrey-strong": $rgba-bluegrey-strong,
    "black-strong": $rgba-black-strong,
    "stylish-strong": $rgba-stylish-strong,
    "white-strong": $rgba-white-strong,
);

$material-colors: (
    "primary": $primary-color,
    "danger": $danger-color,
    "warning": $warning-color-dark,
    "success": $success-color,
    "info": $info-color,
    "default": $default-color,
    "secondary": $secondary-color,
    "elegant": $elegant-color,
    "unique": map.get($pink, darken-4),
    "dark-green": map.get($green, darken-2),
    "red": map.get($red, darken-2),
    "pink": map.get($pink, lighten-1),
    "purple": map.get($purple, darken-1),
    "deep-purple": map.get($deep-purple, darken-2),
    "indigo": map.get($indigo, base),
    "blue": map.get($blue, darken-2),
    "light-blue": map.get($blue, accent-1),
    "cyan": map.get($cyan, base),
    "teal": map.get($teal, darken-2),
    "green": map.get($green, darken-2),
    "light-green": map.get($light-green, base),
    "lime": map.get($lime, darken-2),
    "yellow": map.get($yellow, darken-2),
    "amber": map.get($amber, darken-2),
    "orange": map.get($orange, darken-2),
    "deep-orange": map.get($deep-orange, lighten-1),
    "brown": map.get($brown, base),
    "grey": map.get($grey, darken-2),
    "blue-grey": map.get($blue-grey, lighten-1),
    "white": $white,
    "black": $black
);

$ctbc: (
    "tabs-cyan": map.get($yellow, "base"),
    "tabs-orange": map.get($red, "darken-1"),
    "tabs-grey": map.get($shades, "white"),
    "tabs-pink": map.get($deep-purple, "base"),
    "tabs-green": map.get($blue, "darken-3"),
    "tabs-primary": map.get($shades, "white")
);

$skin-colors: (
    "mdb",
    "white",
    "black",
    "grey",
    "light-blue",
    "indigo",
    "pink",
    "cyan",
    "navy-blue",
    "deep-purple"
);

$dropdown-colors: (
    "primary"  : $primary-color,
    "danger"   : $danger-color-dark,
    "default"  : $default-color,
    "secondary": $secondary-color,
    "success"  : $success-color,
    "info"     : $info-color,
    "warning"  : $warning-color,
    "dark"     : map.get($material-colors, "elegant"),
    "ins"      : map.get($material-colors, "elegant")
);

$switch-colors: (
    "bg": $secondary-color,
    "checked-lever-bg": color.adjust(color.adjust($secondary-color, $lightness: 25%), $saturation: -25%),
    "unchecked-bg": #F1F1F1,
    "unchecked-lever-bg": #818181,
);

@function map-create() {

    $colormap: ();

    @each $key, $val in $colors {

        @if(map.has-key($val, darken-2)) {
            $colormap: map.merge($colormap, ($key: map.get($val, darken-2)));
        }
    }

    @return $colormap;

}

$mdb-colors: map-create();

$color-palette: map.merge($mdb-colors, $material-colors);

@each $name, $color in $material-colors {
    .bg-#{$name} {
        background-color: $color !important;
    }

    .hr-#{$name} {
        .title-container {
            border-color: $color !important;
        }
    }
}

.zolkusowy-gradient {
  background: #00ff90;
  background: -moz-linear-gradient(top, #00ff90 0%, #59ffdd 47%, #ffff00 100%);
  background: -webkit-linear-gradient(top, #00ff90 0%,#59ffdd 47%,#ffff00 100%);
  background: linear-gradient(to bottom, #00ff90 0%,#59ffdd 47%,#ffff00 100%);
} 