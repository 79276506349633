@use "sass:color";
.storybook {
    &.app {
        height: auto;
    }

    .top-navigation {
        position: relative;
    }

    &.default-components, &.preview-components {
        padding: 20px;
        background: color.adjust($primary-color, $lightness: 15%);
        
        .ui.grid.templates {
            background-color: transparent;
        }
    }

    .ui.icon.button > .icon, .ui.icon.buttons .button > .icon {
        margin: 0 .42857143em 0 -.21428571em !important;
    }

}