.whatsapp-preview {
	&.smartphone {
		// iPhone 12 Pro Dimensions
		width: 390px;
		height: 700px;
		// height: 844px;

		.speech-bubble {
			height: 100%;
		}
	}

	.page {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.marvel-device {
        // zoom: 0.5;
		height: 100%;
		.screen {
			text-align: left;
            height: 100%;
		}
	}

	.screen-container {
		height: 100%;
	}

	/* Status Bar */

	.status-bar {
		height: 25px;
		background: #004e45;
		color: #fff;
		font-size: 14px;
		padding: 0 8px;
	}

	.status-bar:after {
		content: '';
		display: table;
		clear: both;
	}

	.status-bar div {
		float: right;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		margin: 0 0 0 8px;
		font-weight: 600;
	}

	/* Chat */

	.chat {
		height: calc(100% - 69px);
	}

	.chat-container {
		height: 100%;
	}

	/* User Bar */

	.user-bar {
		height: 55px;
		background: #005e54;
		color: #fff;
		padding: 0 8px;
		font-size: 24px;
		position: relative;
		z-index: 1;
	}

	.user-bar:after {
		content: '';
		display: table;
		clear: both;
	}

	.user-bar div {
		float: left;
		transform: translateY(-50%);
		position: relative;
		top: 50%;
		// display: flex;
	}

	.user-bar .actions {
		float: right;
		margin: 0 0 0 20px;
	}

	.user-bar {
		.more, .back {
			display: flex;
		}
	}

	.user-bar .actions.more {
		margin: 0 12px 0 32px;
	}

	.user-bar .actions.attachment {
		margin: 0 0 0 30px;
	}

	.user-bar .actions.attachment i {
		display: block;
		transform: rotate(-45deg);
	}

	.user-bar .avatar {
		margin: 0 0 0 5px;
		width: 36px;
		height: 36px;
	}

	.user-bar .avatar img {
		border-radius: 50%;
		box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
		display: block;
		width: 100%;
		height: 100%;
	}

	.user-bar .name {
		font-size: 17px;
		font-weight: 600;
		text-overflow: ellipsis;
		letter-spacing: 0.3px;
		margin: 0 0 0 8px;
		overflow: hidden;
		white-space: nowrap;
		// width: 110px;
	}

	.user-bar .status {
		display: block;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0;
	}

	/* Conversation */

	.conversation {
		height: calc(100% - 12px);
		position: relative;
		background: #efe7dd url(../images/integrations/whatsapp/bg.png) repeat;
		z-index: 0;
	}

	.conversation ::-webkit-scrollbar {
		transition: all 0.5s;
		width: 5px;
		height: 1px;
		z-index: 10;
	}

	.conversation ::-webkit-scrollbar-track {
		background: transparent;
	}

	.conversation ::-webkit-scrollbar-thumb {
		background: #b3ada7;
	}

	.conversation .conversation-container {
		height: calc(100% - 68px);
		box-shadow: inset 0 10px 10px -10px #000000;
		overflow-x: hidden;
		padding: 0 16px;
		margin-bottom: 5px;
	}

	.conversation .conversation-container:after {
		content: '';
		display: table;
		clear: both;
	}

	/* Messages */

	.message {
		color: #000;
		clear: both;
		line-height: 18px;
		font-size: 15px;
		padding: 8px;
		position: relative;
		margin: 8px 0;
		max-width: 80%;
		word-wrap: break-word;
		z-index: -1;
	}

	.message:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
	}

	.metadata {
		display: inline-block;
		float: right;
		padding: 0 0 7px 0;
		position: relative;
		// bottom: -4px;
	}
	
	.metadata-actions {
		padding: 7px 0;
		text-align: center;
		display: flex;
		justify-content: center;
		gap: 2rem;
		position: relative;
		
		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 0.02rem;
			top: 0;
			left: 0;
			background: #b3ada7;
		}
	}

	.metadata .sent-from {
		color: rgba(0, 0, 0, 0.45);
		font-size: 11px;
		display: inline-block;
	}
	
	.metadata .time {
		color: rgba(0, 0, 0, 0.45);
		font-size: 11px;
		display: inline-block;
		text-align: end;
	}

	.metadata .tick {
		display: inline-block;
		margin-left: 2px;
		position: relative;
		top: 4px;
		height: 16px;
		width: 16px;
	}

	.metadata .tick svg {
		position: absolute;
		transition: 0.5s ease-in-out;
	}

	.metadata .tick svg:first-child {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transform: perspective(800px) rotateY(180deg);
		transform: perspective(800px) rotateY(180deg);
	}

	.metadata .tick svg:last-child {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transform: perspective(800px) rotateY(0deg);
		transform: perspective(800px) rotateY(0deg);
	}

	.metadata .tick-animation svg:first-child {
		-webkit-transform: perspective(800px) rotateY(0);
		transform: perspective(800px) rotateY(0);
	}

	.metadata .tick-animation svg:last-child {
		-webkit-transform: perspective(800px) rotateY(-179.9deg);
		transform: perspective(800px) rotateY(-179.9deg);
	}

	.message:first-child {
		margin: 16px 0 8px;
	}

	.message.received {
		background: #fff;
		border-radius: 0px 5px 5px 5px;
		// float: left;
		display: grid;
	}

	.message.received .metadata {
		// padding: 0 0 0 16px;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.message.received:after {
		border-width: 0px 10px 10px 0;
		border-color: transparent #fff transparent transparent;
		top: 0;
		left: -10px;
	}

	.message.notice {
		--bg-color: #b9eae9;
		background: var(--bg-color);
		border-radius: 5px 0px 5px 5px;
		width: 80%;
		margin:  0 auto;
		margin-top: 16px;
		text-align: center;
		color: darken($color: #b9eae9, $amount: 65);
		font-size: small;
		&::after {
			content: none;
		}
	}
	
	.message.sent {
		background: #e1ffc7;
		border-radius: 5px 0px 5px 5px;
		float: right;
	}

	.message.sent:after {
		border-width: 0px 0 10px 10px;
		border-color: transparent transparent transparent #e1ffc7;
		top: 0;
		right: -10px;
	}

	/* Compose */

	.conversation-compose {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		overflow: hidden;
		height: 50px;
		width: 100%;
		z-index: 2;
	}

	.conversation-compose div,
	.conversation-compose input {
		background: #fff;
		height: 100%;
	}

	.conversation-compose .emoji {
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		border-radius: 5px 0 0 5px;
		flex: 0 0 auto;
		margin-left: 8px;
		width: 48px;
	}

	.conversation-compose .input-msg {
		border: 0;
		flex: 1 1 auto;
		font-size: 16px;
		margin: 0;
		outline: none;
		min-width: 50px;
	}

	.conversation-compose .photo {
		flex: 0 0 auto;
		border-radius: 0 0 5px 0;
		text-align: center;
		position: relative;
		width: 48px;
	}

	.conversation-compose .photo:after {
		border-width: 0px 0 10px 10px;
		border-color: transparent transparent transparent #fff;
		border-style: solid;
		position: absolute;
		width: 0;
		height: 0;
		content: '';
		top: 0;
		right: -10px;
	}

	.conversation-compose .photo svg {
		display: block;
		color: #7d8488;
		font-size: 24px;
		transform: translate(-50%, -50%);
		position: relative;
		top: 50%;
		left: 50%;
	}

	.conversation-compose .send {
		background: transparent;
		border: 0;
		cursor: pointer;
		flex: 0 0 auto;
		margin-left: 8px;
		margin-right: 8px;
		padding: 0;
		position: relative;
		outline: none;
	}

	.conversation-compose .send .circle {
		background: #008a7c;
		border-radius: 50%;
		color: #fff;
		position: relative;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.conversation-compose .send .circle svg {
		font-size: 24px;
		margin-left: 5px;
	}

	/* Small Screens */

	@media (max-width: 768px) {
		.marvel-device.nexus5 {
			border-radius: 0;
			flex: none;
			padding: 0;
			max-width: none;
			overflow: hidden;
			height: 100%;
			width: 100%;
		}

		.marvel-device > .screen .chat {
			visibility: visible;
		}

		.marvel-device {
			visibility: hidden;
		}

		.marvel-device .status-bar {
			display: none;
		}

		.screen-container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.conversation {
			height: calc(100vh - 55px);
		}
		.conversation .conversation-container {
			height: calc(100vh - 120px);
		}
	}
}
