@use "sass:map";
// General Components
.input-container.theme-color {
    display: -moz-inline-grid;
    display: -ms-inline-grid;
    display: inline-grid;
    width: 100%;

    &.error {
        label {
            color: #9f3a38 !important;
        }
        input {
            border-color: #9f3a38 !important;
            &:focus {
                border-color: #9f3a38!important;
            }
        }
    }

    label {
        padding-bottom: 5px;
    }

    input, textarea {
        border-color: $input-border-color !important;
        background-color: transparent !important;
        width: 100%;

        &.grey-bg {
            background-color: color('grey', 'lighten-3') !important;
        }
        
        &.primary-bg {
            background-color: color('primary', 'lighten-3') !important;
        }

        &:focus {
            border-color: $secondary-color !important;
        }
    }
}

.dropdown.theme-color {
    border-color: $input-border-color !important;
    .menu {
        border-color: $input-border-color !important;
    }

    .special-actions {
        font-family: 'Quicksand-SemiBold';
    }

    .label {

        &[value='-901'], &[value='-900'] {
            background-color: color("secondary", "base") !important;
            color: $default-text-color !important;
        }
        
        // &[value='-900'] {
        //     background-color: color("tertiary-teal", "base") !important;
        //     color: $default-text-color !important;
        // }

        box-shadow: none !important;
        background-color: $default-tag-color !important;
        color: color("primary", "base") !important;
        @include border-radius($default-tag-border-radius !important);
        font-family: 'Quicksand-SemiBold';
        font-weight: normal;

        .delete.icon {
            // color: $white;
            opacity: 1;
        }
    }

    .icon {
        &:not(.delete) {
            color: $primary-color;
        }
    }
}

.checkbox.theme-color {
    &.toggle {
        input:checked~label:before {
            background-color: $secondary-color !important;
        }
    }
}

.ui.grid.templates {
    margin-left: 0;
    margin-right: 0;
    background-color: $white;
    .t-column {
        width: auto !important;
        min-width: 35% !important;

        .generic {
            padding: 5px;
        }
    }
}

.template {
    background-color: var(--template-bg-color);
    @include border-radius(4px !important);
    color: $default-text-color !important;
    font-family: 'Quicksand-Bold' !important;
    width: 100%;
    text-align: left !important;
    margin-right: 0 !important;
    // margin-bottom: 10px !important;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25) !important;

    &.is-dragging {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25) !important;
    }

    &.generic {
        color: $template-gray-color;
        max-width: none;
    }

    &.t-disabled {
        // opacity: 0.5;
    }

    cursor: pointer;

    span {
        // width: 100%;
        @include vertical-align();
    }

    .icon {
        @extend .no-margin;
        line-height: 1;
        color: $primary-color;
    }

    .delete-icon {
        position: absolute;
        right: 8%;
        display: none;
        color: $template-delete-btn-color;
    }

    .name {
        // @extend .no-margin;
        line-height: 1;
        font-size: 13px;
        color: $default-text-color;
        margin-left: 5px;
    }

    &:hover {
        .name {
            color: $primary-color;
        }

        .delete-icon {
            display: block;
            height: 35px;
            line-height: 35px;
            width: 30px;
            cursor: pointer;
        }
    }
}

.editor-container {
    background-color: $white;
    @extend .z-depth-1;
    padding: 20px;
    min-height: 200px;
    @include border-radius($default-border-radius);
    position: relative;

    .label {
        // margin-bottom: 20px;
    }

    .drag-indicator {
        font-size: 15px;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: grab;
        color: $primary-color;
    }

    .rdw-editor-main {
        padding: 5px;
        border: 1px solid $input-border-color;
        @include border-radius($default-border-radius);

        &:focus {
            border: 1px solid $secondary-color;
        }
    }
}

.drop-drop-zone {
    // margin-top: 20px;
    // min-height: 300px;

    .column.pad-bot-15 {
        &:last-child {
            padding-bottom: 0;
        }
    }

    &.incoming {
        background-color: color('primary', 'lighten-3');

        // Update text-with-line bg
        .text-with-line span {
            background-color: color('primary', 'lighten-3') !important;
        }
    }
}

.incoming-drop {
    background-color: color('primary', 'lighten-3') !important;

    // Update text-with-line bg
    .text-with-line span {
        background-color: color('primary', 'lighten-3') !important;
    }
}

.builder-area, .storybook {
    // width: 70%;

    .label {
        // padding-left: 10px;
        &.optional {
            color: $secondary-color;
        }
    }
}

.info-icon, .user-template-icon {
    font-size: 20px;
    
    &.floating {
        position: absolute;
        right: -5px;
        top: -5px;
        color: $primary-color;

        &.secondary {
            color: $secondary-color;
        }
    }

    &.morphed {
        &.in-label {
            margin-left: 10px;
        }
    }
}

.user-template-icon {
    @include hover {
        cursor: pointer;
    }
}

.only-on-morph {
    display: none;
}

.morphed {
    .only-on-morph {
        display: block;
    }   
}

.transformed-content-container {
    

    &.event-description {
        background-color: $white;
        min-height: 90px;
    }

    &:not(.event-description) {
        background-color: $white;
        @extend .z-depth-1;
        padding: 20px 35px;
        min-height: 90px;
        @include border-radius($default-border-radius);
        position: relative;

        .label {
            // margin-bottom: 20px;
            font-weight: bold;
            &.no-bold {
                font-weight: normal;
            }
        }

        &.has-error {
            // border-left: 5px solid color("red", "base");
            @include hr-line-indicator-activate(color("red", "base"));
        }
    
        .drag-indicator {
            display: none;
            justify-content: center;
            color: $primary-color;
            .material-icons {
                transform: rotate(90deg);
            }
        }
    
        @include hr-line-indicator(color("primary", "base"));
    
        &:focus-within {
            @extend .z-depth-sharp;
            @include hr-line-indicator-activate();
    
            .drag-indicator {
                @include vertical-align();
            }
        }
    
        &:hover {
            @extend .z-depth-sharp;
            .drag-indicator {
                @include vertical-align();
            }
        }   
    }

    .rdw-editor-main {
        padding: 5px;
        border: 1px solid $input-border-color;
        @include border-radius($default-border-radius);
        cursor: text;
        min-height: 90px;

        &:focus {
            border: 1px solid $secondary-color;
        }
    }

    .icons-container {
        margin-top: 5px !important;

        button {
            @extend .button;

            svg {
                font-size: 20px;
            }
            
            &:hover {
                color: $secondary-color !important;
            }
        }
    }

    .button {
        &:not(.ui.circular) {
            color: $primary-color !important;
            background-color: transparent !important;
        }
    }

    .content-container {
        // margin-top: 20px;
    }

    .image {
        &.add-new, &.file-add {
            cursor: pointer;
            @include border-radius(5px);
            @include vertical-align();
            justify-content: center;
            
            border: 0.5px solid;
            margin: 0;
            
            &:hover {
                border: 0.5px solid $secondary-color !important;
            }
        }

        &.file-add {
            width: 100px !important;
            height: 100px !important;
        }

        &.add-new {
            height: 80px !important;
        }
    }

    .image.mg-similar-morphed {
        &:has(.file-add) {
            width: 100px !important;
        }

        margin: 0.5rem 0.25rem 0.5rem !important;
    }

    .thumb-image-container {
        margin: 0.5rem 0.25rem 0.5rem !important;
        position: relative;
        
        .morphius {
            margin: 0;
            border: 0.5px solid;
            z-index: 1;
        }

        &.is-dragging {
            background-color: rgba(0, 0, 0, 0.5);
            opacity: .5;
            background-blend-mode: color;
        }

        .file-action-buttons {
            display: none;
            z-index: 2;
            position: absolute;
            top: -10px;
            left: -10px;
            max-width: 100px;
            transition: all 0.3s ease-in-out;
            background-color: white;

            .ui.icon.button {
                padding: 0.3rem;
                
                &:hover {
                    color: color('primary', 'base') !important;
                }
            }
        }

        &:hover {
            .file-action-buttons {
                display: inline-flex;
            }
        }

        .icon.remove-image-icon {
            // background-color: $secondary-color;
            color: color('grey', 'darken-1');
            position: absolute;
            right: calc(-1rem/2);
            top: calc(-1rem/2);
            font-size: 1rem;
            transition: all 0.3s ease-in-out;
            margin: 0;

            &:hover {
                font-size: 1.8rem;
                right: calc(-1.8rem/2);
                top: calc(-1.8rem/2);
            }
        }
        
        &.loading, &.error {
            .image-component.morphius {
                background-color: rgba(0, 0, 0, 0.5);
                opacity: .5;
                background-blend-mode: color;
            }
            
            .file-morphed-container {
                background-color: rgba(0, 0, 0, 0.5);
                opacity: .4;
                .f-name, .f-size {
                    color: black !important;
                }
                background-blend-mode: color;
            }
        }

        --icon-size: 80px;
        &:has(.file-morphed-container) {
            --icon-size: 90px;
        }

        .icon.loading-icon {
            --font-size: 1.5rem;
            --font-size-half: 2rem;
            
            position: absolute;
            left: calc(var(--icon-size)/2 - var(--font-size)/2);
            top: calc(var(--icon-size)/2 - var(--font-size)/2);
            font-size: var(--font-size);
            margin: 0;
            color: white;
            z-index: 2;
            transition: all 0.1s ease;

            &.error {
                color: color('red', 'darken-1');
                &:hover {
                    left: calc(var(--icon-size)/2 - var(--font-size-half)/2);
                    top: calc(var(--icon-size)/2 - var(--font-size-half)/2);
                    font-size: var(--font-size-half);
                }
            }
        }
    }
}

@mixin preview-list($bg-color: red) {
    .preview-list {
        @content;
        
        .preview-list-item {
            position: relative;
            background-color: $bg-color;
            
            &.no-bg {
                background-color: transparent;
            }

            // @extend .z-depth-1;
            min-height: 70px;
            @include border-radius($default-border-radius);
            &:not(.image-groups) {
                padding: 10px !important;
            }

            &.image-groups {
                background-color: transparent;
                .eight.wide.column {
                    padding: 0.8em;
                    
                    &:nth-child(even) {
                        padding-right: 0;
                    }

                    &:nth-child(odd) {
                        padding-left: 0;
                    }
                }
            }

            // border: 1px solid $preview-list-item-border-color;

            &.no-padding {
                padding: 0 !important;
            }

            margin-bottom: 10px;

            .date-text, .description-text {
                color: $default-text-color;
                word-break: break-word;
                
                ul {
                    display: block;
                    list-style-type: disc;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 18px;

                    ul {
                        list-style-type: circle;
                        ul {
                            list-style-type: square;
                        }
                    }
                }

                ol {
                    padding-inline-start: 18px;
                }

                ul, ol {
                    margin: 0;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    // margin: 0;
                }

                blockquote {
                    background: #f9f9f9;
                    // border-left: 10px solid #ccc;
                    border-left: 5px solid #ccc;
                    // margin: 1.5em 10px;
                    padding-left: 5px;
                    quotes: "\201C""\201D""\2018""\2019";
                }

                // The preview does not render the text component data correctly when an empty new line is entered
                p {
                    &:empty {
                        padding: 1px;
                    }
                }
                
                &.description p {
                    &:not(:empty) {
                        margin: 0;
                    }

                    ul {

                    }
                }
            }

            .title-text {
                color: $primary-color !important;
                font-weight: bold !important;

                .warning.circle {
                    padding-right: 10px;
                }
            }

            .logo-image {
                height: 40px;
                width: 40px;
                object-fit: contain;
            }

            .web-frame {
                min-height: 100px;
                width: 100%;
                border: 0;
            }

            &.payment-item {

                .title {
                    color: $black;
                    @extend .no-margin;
                    // font-weight: bold;
                }

                .amount {
                    color: $primary-color;
                    font-weight: bold;
                    font-family: 'Quicksand-Bold';
                    font-size: 1.3em;
                }

                
                .pay-button-container {
                    @extend .vertical-align;
                    justify-content: center;
                    
                    .pay-button {
                        color: $white;
                        background-color: $primary-color;
                        text-transform: uppercase;
                        padding: 0.5em 1em .5em;
                        font-weight: normal;
                    }
                }
            }

            .content {
                &.list-item {
                    padding-left: 10px;
                    width: 70% !important;
                }
            }

            &.important {
                // background-color: color('red', 'lighten-4');
                color: color('red', 'darken-3') !important;
                // * {
                //     color: color('red', 'darken-3') !important;
                // }
                .title-text, .date-text, .description-text, .pin-item {
                    color: color('red', 'darken-3') !important;
                }
            }

            .pin-item {
                position: absolute;
                right: 10px;
            }

            &.icon-card-container {
                .icon-container {
                    @include vertical-align();
                    justify-content: center;
                    background: $primary-color;
                    @include border-left-radius(5px !important);

                    .icon {
                        font-size: 1.5rem;
                        height: auto;
                        color: $white;
                        margin: 0;
                    }
                }

                .rep__all-day {
                    p {
                        font-size: 18px;
                    }

                    h1 {
                        line-height: inherit;
                    }
                }

                .contact {
                    
                    &-name, &-email, &-job-title, &-number {
                        padding-bottom: 5px;
                    }
                    
                    &-email, &-number {
                        color: $black;
                    }

                    &-name {
                        font-weight: 700;
                        font-size: 1em;
                    }

                    &-job-title {
                        color: map.get($grey, 'lighten-1');
                    }
                }

                .deadline {
                    strong {
                        color: $primary-color;
                    }
                }
            }

            .image-scrollable {
                @include flexbox();
                @include scrollbars(1px, $primary-color);
                
                overflow-x: scroll;
                padding-bottom: 5px;

                .image {
                    margin-right: 5px;
                    height: 80px;
                }
            }

            .video-container {
                video {
                    @include border-radius($default-border-radius);
                    width: 100%;
                }
            }
        }
    }
}

.message-view {
    $padding: 20px;
    --preview-font-size: 2;
    position: relative;
    min-height: $smartphone-height;
    background-color: transparent;
    margin-top: 20px;
    color: $black;

    // Disable interaction
    // pointer-events: none;
    // user-select: none;
    
    .header {
        // background-color: $primary-color;
        // min-height: 100px;
        padding: $padding;
        padding-bottom: 0;
        // color: $white;
        // @include border-top-radius(5px);

        .icon {
            font-size: 20px;
        }

        .notice-title {
            font-size: 1.10rem;
            @extend .no-margin;
        }

        .date-text, .comm-name {
            color: color('grey', 'base');
            font-size: 12px;
        }

        .item-title {
            font-family: 'Quicksand-Regular' !important;
        }

        .ui.image.rounded {
            object-fit: cover;
        }
    }

    .important {
        .notice-title, .comm-name {
            color: color('red', 'darken-3') !important;
        }
    }

    .vote-component {
        display: flex;
        gap: 15px;
        flex-direction: column;
        position: relative;

        &-item {
            display: flex;
            gap: 10px;
            background: $preview-bg-color;
            padding: 10px;
            align-items: center;
            @include border-radius($default-border-radius);
            min-height: 50px;
            
            .candidate {
                
                &-info {

                }
                
                &-name {
                    flex: 1;
                }

                &-item {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    flex: 1;
        
                    .avatar {
                        height: 50px;
                        width: 50px;
                        object-fit: cover;
                        background-color: color('primary', 'lighten-3');
                    }
                }
            }
        }
    }

    .payment-item {
        @include vertical-align();
        &.preview-list-item > .content {
            width: 100%;
        }
    }

    .itemised-controls {
    
        .input {
            width: 100%;
            input {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }
        }

        .control {
            padding: 0 !important;
            .button {
                margin: 0 !important;
            }
            &.plus {
                text-align: left;
            }
            
            &.minus {
                text-align: right;
            }
        }
    }

    .spacer-bottom {
        padding-bottom: 15px;
    }
    
    @include preview-list($white) {

        &.m-content {
            padding: $padding;
            margin-top: 0;
            border-top: 0.2rem solid $preview-bg-color;
    
            .ui.image.rounded {
                object-fit: cover;
            }
        }

        .preview-list-item, [role="preview-list-item"] {
            margin-top: 15px;
            box-shadow: none;

            ul {
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 18px;
            }

            ol {
                padding-inline-start: 18px;
            }
            
            &:last-child {
                margin-bottom: 30px;
            }
        }
    }

    .required-star {
        font-size: 15px;
    }

    .marks-preview {
        .progress {
            // height: 10px;
            position: relative;
            margin-bottom: 0 !important;
            
            .bar {
                min-width: 0 !important;
                background-color: map.get($green, "base");
            }
        }
        
        .avg-line {
            position: absolute;
            color: black;
            background: black;
            height: 10px;
            width: 2px;
            margin: 0;
            top: -2px;
        }
    }

    .mark-value {
        color: map.get($green, "base");
    }

    .url-component {
        .url-thumb-preview {
            width: 4rem;
            height: 4rem;
            border-radius: 0.5rem;
            display: inline-block;
            margin-right: 1.5rem;
        }
    }
}

.list-view {
    margin-top: 20px;

    @include preview-list($preview-list-item-bg-color) {
        
    }
}

.list-view.preview-modal {
    margin-top: 20px;

    @include preview-list($white) {
        
    }
}

.dropdown-float-right {
    text-align: right;
    padding-right: 30px !important;
}

.attachment-morphed {

    .custom-switch-group {
        @include vertical-align();
        @include justify-content(flex-end);
    }

    .ui.image.thumb-image-container {
        width: auto !important;
        max-width: 100px;
    }
    
    .file-morphed-container {
        border: 0.5px solid;
        @include border-radius($default-border-radius);
        padding: 5px;
        background: $white;
    }

    .file-details {
        padding: 0 !important;
    }
}

.message-thumbnails {
    
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto;
    white-space: nowrap;
    gap: 5px;

    @include scrollbars(0px, $primary-color);
    
    --image-size: 30px;

    .items-list {
        display: flex;
        gap: 5px;
        width: 100%;
        overflow: hidden;
        @include scrollbars(0px, $primary-color);
    }

    .image-previews, .file-previews {
        display: flex;
        align-items: center;
        gap: 5px;

        &.compact {
            gap: 0px;

            .image-component {
                &:not(.more-count) {
                    &:nth-child(1) {
                        @extend .z-depth-1;
                        z-index: 3;
                    }
                    &:nth-child(2) {
                        @extend .z-depth-2;
                        z-index: 2;
                        width: calc(var(--image-size) - 2px);
                        height: calc(var(--image-size) - 2px);
                    }
                    &:nth-child(3) {
                        @extend .z-depth-3;
                        z-index: 1;
                        width: calc(var(--image-size) - 4px);
                        height: calc(var(--image-size) - 4px);
                    }
    
                    &:not(:first-child) {
                        margin-left: -11px;
                    }
                }

                &.more-count {
                    margin-left: 2px;
                    background-color: transparent;
                    width: fit-content;
                }
            }
        }
        
        .image-component {
            width: var(--image-size);
            height: var(--image-size);

            // &:last-child {
            //     margin-right: 5px;
            // }
        }

        .file-component {
            max-width: 100px;
            @include border-radius(10px !important);
        }

        .adaptive-icon {
            margin-right: 5px;
            line-height: 1.5rem;
        }
    }

    .file-previews {
        &.with-bg {
            background-color: color('grey', 'lighten-3') !important;
        }
        
        @include border-radius(10px !important);

        &.f-compact {
            padding: 2px 15px;
        }
    }
    
    .form-label {
        @include border-radius(10px !important);

        &.item-type-form, &.unpaid-item {
            .icon {
                margin-right: 5px;
            }
            
            color: $template-delete-btn-color;//red
            * {
                color: $template-delete-btn-color;//red
            }

            background-color: lighten(color('red', 'base'), 35%) !important;
        }

        &.more-item {
            // border: 1px solid;
            width: fit-content;
            min-width: auto;
            @include vertical-align();
            background-color: color('grey', 'lighten-3');
        }

        &.icon-text {
            .icon {
                margin-right: 5px;
                color: color('grey', 'darken-3');
            }
        }

        .icon {
            margin: 0;
        }

        &.item {
            @include vertical-align();
            text-align: center;
            text-decoration: none;
            background-color: color('grey', 'lighten-3');
            // border: thin solid;
            max-width: 30%;
            min-width: 90px;
        }

        .secondary-icon {
            color: map.get($grey, 'base');
        }

        &.required {
            span.secondary {
                color: map.get($red, 'base');
            }
            
            .secondary-icon {
                color: map.get($red, 'base');
            }
        }

        .response-text {
            font-size: 8px;
            padding: 2px 0 !important;

            span {
                &.initial {
                    // font-size: 8px;
                }

                &.secondary {
                    // 
                }
            }
        }

        .pad-{
            @for $i from 1 through 100 {
                &both-#{$i} {
                    padding-top: ($i + 0px) !important;
                    padding-bottom: ($i + 0px) !important;
                }
            }
        }
    }

    display: flex;
    .read-indicator {
        width: 40px;
        min-width: 40px;
        text-align: center;
        position: relative;

        .circle.icon {
            position: relative;
            top: -10px;
            z-index: 200;
            margin: 0;
            color: #407fe2;
            font-size: 9px;
        }
    }
}

.DraftEditor-editorContainer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // margin: 0;
    }
}

.side-menu-col-name {
    @include vertical-align();
    color: $default-text-color;

    .icon {
        color: $default-text-color;
        @include vertical-align();
        margin-left: 10px;
    }
}

.white-bg-container {
    background: $white;
    margin: 0 15px;
    padding: 20px !important;
    @include border-radius(.5rem);
    @extend .z-depth-1;

    .label.strong {
        font-weight: bold;
    }
}

.white-bg-container, .event-title-container {
    .label {
        &.grey-text, &.error-text {
            margin-top: 10px;
            font-size: 80%;
        }
        &.error-text {
            color: color('red', 'base') !important;
        }
    }
}

.notice-title-input-container {
    .input > input {
        border: 0 !important;
        border-radius: 0 !important;
        padding: 15px 5px !important;
        padding-bottom: 5px !important;
        border-bottom: 0.5px solid color('grey', 'lighten-1') !important;
        font-size: 2em !important;
    }

    .label {
        &.grey-text, &.error-text {
            margin-top: 10px;
            font-size: 80%;
        }

        &.error-text {
            color: color('red', 'base')
        }
    }
}

.web-frame-placeholder, .youtube-placeholder, .image-placeholder, .url-placeholder {
    height: 150px;
    @include vertical-align();
    justify-content: center;
    font-size: 50px;
    color: color('grey', 'lighten-1');
}

.url-placeholder {
    flex-direction: column;
    small {
        font-size: 14px;
    }
}

.file-upload-placeholder {
    height: 80px;
    @include vertical-align();
    justify-content: center;
    font-size: 40px;
    color: color('grey', 'lighten-1');
}

.rdw-list-dropdown  {
    z-index: auto !important;
}

.template-info {
    padding: .78571429em 1.5em .78571429em;
}

[data-rbd-drag-handle-context-id="1"] {
    outline: none;
}

.smartphone.sms-preview, .smartphone.whatsapp-preview {
    --bubble-bg: #e5e5ea;
    
    .speech-bubble {
        background-color: #fff;
        border: 1px solid var(--bubble-bg);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        margin: 0 auto 1rem;
        max-width: 600px;
        padding: 0.5rem 1.5rem;
      }
      
      .speech-bubble p {
        border-radius: 1.15rem;
        line-height: 1.25;
        max-width: 80%;
        padding: 0.5rem .875rem;
        position: relative;
        word-wrap: break-word;
      }
      
      .speech-bubble p::before,
      .speech-bubble p::after {
        bottom: -0.1rem;
        content: "";
        height: 1rem;
        position: absolute;
      }
      
      p.from-me {
        align-self: flex-end;
        background-color: #248bf5;
        color: #fff; 
      }
      
      p.from-me::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid #248bf5;
        right: -0.35rem;
        transform: translate(0, -0.1rem);
      }
      
      p.from-me::after {
        background-color: #fff;
        border-bottom-left-radius: 0.5rem;
        right: -40px;
        transform:translate(-30px, -2px);
        width: 10px;
      }
      
      p[class^="from-"] {
        margin: 0.5rem 0;
        width: fit-content;
      }
      
      p.from-me ~ p.from-me {
        margin: 0.25rem 0 0;
      }
      
      p.from-me ~ p.from-me:not(:last-child) {
        margin: 0.25rem 0 0;
      }
      
      p.from-me ~ p.from-me:last-child {
        margin-bottom: 0.5rem;
      }
      
      p.from-them {
        align-items: flex-start;
        background-color: var(--bubble-bg);
        color: #000;
        zoom: 0.7;
        position: absolute;
        // bottom: 15px;
      }
      
      p.from-them:before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid var(--bubble-bg);
        left: -0.35rem;
        transform: translate(0, -0.1rem);
      }
      
      p.from-them::after {
        background-color: #fff;
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
      }
      
      p[class^="from-"].emoji {
        background: none;
        font-size: 2.5rem;
      }
      
      p[class^="from-"].emoji::before {
        content: none;
      }
      
      .no-tail::before {
        display: none;
      }
      
      .margin-b_none {
        margin-bottom: 0 !important;
      }
      
      .margin-b_one {
        margin-bottom: 1rem !important;
      }
      
      .margin-t_one {
        margin-top: 1rem !important;
      }
      
}

.rdw-emoji-wrapper, .rdw-ai-assist-wrapper {
    position: relative;
    
    .custom-emoji__picker {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 357px;
        // width: 338px;
        // overflow: hidden;
        z-index: 10;
    }
    
    .custom-ai-dropdown__menu {
        position: absolute;
        right: 0 !important;
        min-width: unset !important;
        width: 260px !important;
        // overflow: hidden;
        z-index: 10;
        top: 30px !important;
        min-height: 377px !important;

        @include mq_medium_down() {
            left: auto !important;
        }
    }

    .rdw-ai-assist-options-wrapper {
        [role=listbox] {
            padding: 0.6rem !important;
            > span {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            &.disabled {
                color: $black !important;
                
                svg {
                    fill: $black;
                }

                .secondary {
                    background-color: transparent !important;
                    outline: 1px black solid;
                }
            }

            .ui.mini.label {
                padding: 2px 5px !important;
                margin-right: 0;
            }
        }

        [role=listbox] > span + .icon {
            display: none;
        }
        box-shadow: none !important;
        padding: 0;

        .item {
            gap: 2px;
            display: flex !important;
            flex-direction: column;
            white-space: pre-wrap !important;

            &:hover {
                .row-1 .title {
                    color: $primary-color;
                }
            }

            .row-1 {
                display: grid;
                grid-template-columns: 0.15fr 1fr;
                align-items: center;
                
                .icon {
                    &.simplify {
                        width: 25px;
                        height: 25px;
                    }

                    width: 18px;
                    height: 18px;
                }
            }

            .opt-description {
                display: grid;
                grid-template-columns: 0.15fr 1fr;
                font-size: small;
                color: color('grey', 'lighten-1');
                margin-bottom: 2px;
            }

            .text-group {
                display: flex;
                align-items: center;
                gap: 2;
                svg {
                    font-size: 2rem;
                }
            }
        }
    }
}

.builder-top-navigation {
    @extend .top-navigation;
    top: $builder-top-nav-height;
    left: $sidebar-collapsed-width;
    width: calc(100% - #{$sidebar-collapsed-width}) !important;
    transition: position 0.4s ease;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,0.15);
    background-color: $white;
    min-height: $top-nav-height;
    height: auto !important;

    > .row {
        width: 100%;
    }

    // div.column {
    //     border: 0.1px solid red;
    // }

    .side-menu-col-name {
        // padding-left: 15px;
        cursor: pointer;
        &:hover {
            color: $primary-color;
            .icon {
                color: $primary-color;
            }
        }
    }

    &.sn-opened {
        left: $sidebar-width;
        width: calc(100% - #{$sidebar-width}) !important;
    }

    z-index: 199 !important;
    .item {

        &.column-title {
            width: fit-content;
        }

        @extend .no-line;
        @extend .no-padding;
        width: 50%;

        &[role="option"] {
            width: 100%;
            min-height: 50px;
            @include vertical-align();
            .template {
                margin-bottom: 0 !important;

                &.user-template {
                    .template-info {
                        .name {
                            margin: 0;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        &[role="listbox"] {
            width: fit-content;
            .button {
                .icon.dropdown {
                    margin: 0;
                    margin-left: 5px;
                }
            }
            @include vertical-align();

            .menu.visible {
                left: -50px;
            }
        }
    }

    .ui.grid.templates {
        width: 100%;
        @extend .no-margin;

        .template {
            margin-bottom: 0 !important;
        }
        
        .row {
            @extend .no-padding;

            > .column {
                @include vertical-align();
                max-width: fit-content !important;
                min-height: 50px;
                justify-content: center;
            }
        }
    }
}

.label.grey-text.small-text {
    margin-top: 10px;
    font-size: 80%;
}

.icon-card-container {
    .file-attachment {
        height: 100px;
    }
    
    .file-details {
        text-align: center;
        padding: 15px;
        .f-{
            &name, &size {
                font-family: 'Quicksand-SemiBold';
            }

            &name {
                @extend .trim-text;
                color: color('grey', 'lighten-1');
            }

            &size {
                color: color('grey', 'base');
            }
        }
    }
}

.file-action-buttons {
    display: none;
    z-index: 2;
    position: absolute;
    top: -10px;
    left: -10px;
    max-width: 100px;
    transition: all 0.3s ease-in-out;
    background-color: white;

    .ui.icon.button {
        padding: 0.3rem;
        
        &:hover {
            color: color('primary', 'base') !important;
        }
    }
}

.color-secondary-dark {
    background-color: color("secondary", "base") !important;
    color: $default-text-color !important;
}

.vote-option-container {
    --template-bg-color: #F3F3F3;
    background-color: var(--template-bg-color);
    border-radius: 4px !important;
    color: #030c16 !important;
    width: 100%;
    text-align: left !important;
    margin-right: 0 !important;
    cursor: pointer;
    
    .input-container.theme-color .ui.input, .rdw-editor-main {
        background-color: white;
    }

    .option-action-buttons {
        display: none;
        z-index: 2;
        position: absolute;
        top: -25px;
        right: -7px;
        max-width: 200px;
        transition: all 0.3s ease-in-out;
        background-color: white;

        .ui.icon.button {
            padding: 0.3rem;
            
            &:hover {
                color: color('primary', 'base') !important;
            }
        }
    }

    &:hover {
        .option-action-buttons {
            display: inline-flex;
        }
    }

    .dropzone {
        padding: 10px;
    }
}

.attachment-preview {
    &.preview-list-item {
        margin: 0 !important;
        
        .list-view  {
            margin: 0 !important;
        }
        .header {
            box-shadow: none !important;
            padding: 0 !important;
        }
    }
}

.whatsapp-preview {
    --bubble-bg: white !important;
    
    .from-them {
        min-width: 80%;
    }

    .speech-bubble {
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        background-color: transparent !important
    }

    .message-view  .preview-list {
        border: none !important;
        height: calc(100% - 78px);
        background: url(../../images/integrations/whatsapp/bg.png);
    }
    
    .header {
        box-shadow: none !important;
        padding: 0 !important;
        background-color: #00a884 !important;
        color: white;

        .ui.image.rounded {
            width: 50px;
            height: 50px;
            border-radius: 50% !important;
        }
    }
}

.vote-option-container {
    .img-thumb-move {
        display: none !important;
    }
}

.modal > .content > .chart-container {
    min-height: 25rem;
    .chart-legend, .totals-label {
        font-size: inherit;
    }

    .totals-caption {
        font-size: 3rem
    }
    @extend .no-margin;
}

.expand-action-buttons {
    z-index: 2;
    position: absolute;
    top: -1.5rem;
    right: -10px;
    transition: all 0.3s ease-in-out;
    background-color: white;

    .ui.icon.button {
        padding: 0.3rem;
        
        &:hover {
            color: color('primary', 'base') !important;
        }
    }
}

.payment-add-on {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: color('primary', 'base');
    color: white;
    padding: .78571429em .5em .78571429em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
    font-size: .85714286rem;
    @include border-radius($default-tag-border-radius !important);

    &:hover {
        background-color: var(--template-bg-color);
        color: #030c16;
    }
}

.deadline-form-container {
    .field {
        :has(.checked) {
            outline: 1px solid color('primary', 'base');
            border-radius: 0.5rem;
            background-color: color('primary', 'lighten-3');
        }

        :has(:disabled) {
            opacity: 0.8;
            border-radius: 0.5rem;
            background-color: color('grey', 'lighten-4');
        }

        > label {
            cursor: pointer;
            display: flex !important;
            gap: 1px;
            align-items: center;
            padding: 0.5rem 1rem;
            
            span.container {
                display: flex;
                padding: 1rem;
                gap: 6px;
                flex-direction: column;

                .title {
                    font-size: 15px;
                    font-family: 'Quicksand-SemiBold';
                    font-weight: bold;
                }
                
                .description {
                    font-family: 'Quicksand-Regular';
                    font-weight: normal;
                }
            }
        }
    }
}

.partial-payments-note {
    border-radius: 0.5rem;
    background: var(--template-bg-color);
    padding: 0.5rem;
    color: $primary-color;

    // &.reversal {
    //     background: color('secondary', 'lighten-3');
    //     color: color('secondary', 'darken-2');
    // }
}

.totals-container {
    display: flex;
    padding: 0.3rem 0;
    justify-content: space-between;

    &:first-child {
        margin-top: 1rem;
    }
    &:last-child {
        :has(button.not-relevant) {
            border-bottom: 1px solid color('grey', 'lighten-3');
        }
    }

    &:has(button.not-relevant) {
        // border-top: 1px solid color('grey', 'lighten-3');
        justify-content: end;
    }

    button.not-relevant {
        margin: 0;
    }

    span {
        // font-family: 'Quicksand-SemiBold';
        // font-size: 15px;
    }
}

.import-form-container {
    .error-container {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        font-family: 'Quicksand-SemiBold';
        font-weight: bold;

        &:empty {
            display: none;
        }
    }

    .field {
        outline: 1px solid color('grey', 'base');
        border-radius: 0.5rem;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 1.5rem;

        &:has(.checked) {
            background-color: color('primary', 'lighten-3');
            outline-color: color('primary', 'base');
        }

        :has(:disabled) {
            opacity: 0.8;
            border-radius: 0.5rem;
            background-color: color('grey', 'lighten-4');
        }

        > label {
            cursor: pointer;
            display: flex !important;
            gap: 1px;
            align-items: center;
            padding: 0.5rem 1rem;
            
            .crown-icon {
                top: 4px;
                font-size: 1.2rem;
                right: 4px;
            }

            span.container {
                display: flex;
                padding: 1rem;
                gap: 6px;
                flex-direction: column;

                .title {
                    font-size: 15px;
                    font-family: 'Quicksand-SemiBold';
                    font-weight: bold;
                }
                
                .description {
                    font-family: 'Quicksand-Regular';
                    font-weight: normal;
                }
            }
        }
    }
}

.event-notes {
    .description-text {
        color: $default-text-color !important;
    }
}

.selection-limits {
    padding: 1rem;
    outline: 1px solid $input-border-color;
    @include border-radius($default-border-radius);
    width: 50%;

    .action-buttons {
        flex: auto;
        justify-content: end;
    }

    .parent-container {
        &:nth-child(1) {
            margin-bottom: 0.5rem;
        }
    }

    .parent-container, .action-buttons {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }
}