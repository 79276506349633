body {
    @include mq_medium_down {
    //   width: $medium-screen;
    //   overflow: scroll;
  
      .community-selection-dropdown {
        p.no-margin {
          display: none;
        }
      }
    }
}

.top-navigation {
    
    .ham-container {
        &.visible-md-down {
            display: none !important;
        }
    }

    @include mq_medium_down {

        .org-dropdown-container {
            img {
                margin: 0 !important;
            }
            .community-name {
                display: none;
            }
            
            .visible.menu.transition {
                width: auto  !important;

                [role="option"] {
                    text-align: center;
                }
            }

        }
        
        .profile-dropdown {
            width: 50px !important;

            .user.icon {
                margin: 0 !important;
            }            
            .text, .dropdown.icon {
                display: none !important;
            }
        }

        .ham-container {
            &.visible-md-down {
                display: flex !important;
            }
        }
    }

    @include mq_small_down {
        .tiering-plan-menu {
            .hide-on-small-down {
                display: none;
            }
        }

        .logo-container {
            width: auto !important;
        }
    }
}

.pro-sidebar {
    @include mq_medium_down {
        &:not(.preview) {
            .pro-sidebar-inner {
                background-color: $white;
            }
        }
    }
}

.sidebar-floating-btn-wrapper {

    @include mq_medium_down {

        &.bsn-opened {
            left: $sidebar-width !important;
        }
        
        &.bsn-collapsed{
            left: 0 !important;
            z-index: 1;
        }
    }
}

.preview-sidebar-floating-btn-wrapper {

    @include mq_medium_down {
        z-index: 224;
        
        &.visible-md-down {
            display: block;
        }

        &.opened {
            right: $preview-builder-side-nav-width !important;
        }
        
        &.closed{
            right: 0 !important;
        }
    }
}

.secondary-sidebar, .builder-top-navigation {
    @include mq_medium_down {
        &.preview {
            padding-top: 0 !important;
            margin-top: 0 !important;
            z-index: 223;

            .pro-sidebar-inner {
                background-color:  $preview-bg-color;
            }

            left: auto !important;
            right: -$preview-builder-side-nav-width !important;

            &.toggled {
                right: 0 !important;
            }
        }
    }
}

.builder-top-navigation {

    .builder-tn-row {
        // @include mq_medium_down {
        //     padding: 0;
        // }
    }

    @include mq_medium_down {
        
        .template-info {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        // left: auto !important;d
        &.sn-opened, &.sn-collapsed {
            left: 0 !important;
            width: 100% !important;
        }
       
        &.sn-opened {
            left: 0 !important;
            width: 100% !important;
        }

        &.toggled {
            // padding-top: 0 !important;
            // margin-top: 0 !important;
            
            .pro-sidebar-inner {
                background-color:  $preview-bg-color;
            }
            
            left: auto !important;
            
            &.sn-opened {
                z-index: 99 !important;
                // left: $sidebar-width !important;
            }
        }
    }
}

.table-container {
    
    .show-on-small-down {
        display: none;
    }

    @include mq_small_down() {
        table {
            th,td {
                display: none;
                &:first-child, &:nth-child(2), &:last-child {
                    // border: 1px solid red;
                    &:not(.checkbox) {
                        display: table-cell !important;
                    }
                }
                
                &:last-child {
                    width: 4%;
                }
            }

            tbody td {
                &.hide-on-small-down {
                    display: none !important;
                }
                &.show-on-small-down {
                    display: table-cell !important;
                }
            }
        }
    }
}

.filters-container, .pagination-container {
    > .grid {
        @extend .collapse-on-small-down;
    }
}

input {
    &.search {
        @include placeholder {
            color: red;
        }
    }
}

.preview {
    @include mq_medium_down() {
        .message-view {
            margin-bottom: 150px !important;
        }
    }
}

.modal {
    @include mq_small_down() {
        .content {
            padding: 0 !important;
        }
        
        .add-users-modal, .generic-modal {
            .right-side.no-flex {
                .w-90.mg-auto {
                    margin: 0 !important;
                    padding: 0 !important;
                    width: 100%;
                }
            }
        }
    }
}

// Remove top margin and padding to have consistent containers when stacked
.ui.grid + .grid {
  @include mq_small_down() {
    &:not(.responsive) {
        margin-top: 0;
        .column {
            padding: 0;
        }
    }
  } 
}

.ui.grid.responsive {
    
    .ui.input {
        width: 100%;
    }

    .field + small {
        margin: -1em 0 0 !important;
    }

    @include mq_medium_down() {
        > .row {
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
        .column {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    } 
}

.page-section {
    @include mq_medium_down() {
        .cover-image {
            
            $height: 80px;

            .profile-image {
                height: $height !important;
                width: $height !important;
                left: calc(90% - #{$height}/2) !important;
            }

            .update-profile-button {
                height: 40px;
                width: 40px;
                padding: 10px;
                font-size: 14px;
            }
        }

        .page-sub-caption {
            margin-top: 20px;
        }
    }
}

.page {
    &.container {
        @include mq_large_up() {
            max-width: 1000px;
            margin-left: auto !important;
            margin-right: auto !important;

            .page-section {
                &:not(.with-cover) {
                    padding: 25px !important;
                }
            }
        }        
    }
}

.message-builder {
    .default-content-area {
        @include mq_large_up() {
            max-width: 1000px;
            margin-left: auto !important;
            margin-right: auto !important;

            .page-section {
                padding: 25px !important;
            }
        }
    }
}

.link-calendar {
    @media screen and ( max-height: 768px )
    {
        .link-calendar-full-page {
            display: block;
            padding-top: 5rem;
        }
    }
}

// TODO: Side menu does not scroll when the hight of the screen gets very low and content overflows
// setting the overflow-y: auto; to make it scrollable actually cuts of the pop-up links