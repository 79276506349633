@use "sass:map";
// Margin and Padding

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {

    .#{$abbrev}-#{$size}  { #{$prop}:        $length !important; }
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length !important; }
    .#{$abbrev}x-#{$size} {
        #{$prop}-right: $length !important;
        #{$prop}-left:  $length !important;
    }
    .#{$abbrev}y-#{$size} {
        #{$prop}-top:    $length !important;
        #{$prop}-bottom: $length !important;
    }
    }
}

@each $key, $val in $grid-breakpoints {
    @include media-breakpoint-up($key) {
        $y: map.get($extreme-padding, $key);
        .e-px {
            padding-left: $y;
            padding-right: $y;
        }
    }
}

.img-fluid {
    max-width: 100%;
    height:auto;
}

.inline-ul>li {
    display: inline;
}

.list-inline-div>div {
    display: inline-block;
}

// Center text on mobile
.center-on-small-only {
    @media #{$medium-and-down} {
        text-align: center;
        .img-fluid {
            display: inline;
        }
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    p {
        margin: 0;
    }
    ul {
        text-align: center;
        li {
            margin-bottom: 1rem;
        }
    }
}

.hidden-md-down {
    @media (max-width: $medium-screen){
        display: none!important;
    }    
}

.mb-r {
    @media (min-width: $medium-screen){
        margin-bottom: 3rem!important;
    }   
    @media (max-width: $medium-screen){
        margin-bottom: 2rem!important;
    }  
}

.font-bold {
    font-weight: 500;
}

.font-up {
    text-transform: uppercase;
}

// Dividers light and dark variations
.hr-light {
    border-top: 1px solid #fff;
}

.hr-dark {
    border-top: 1px solid #666;
}

// Blockquote contextual 
.blockquote {
    .bq-title {
        font-weight: 400;
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    p {
        font-size: 1.1rem;
    }
}

.bq-primary {
    border-left: 3px solid $primary-color;
    .bq-title {
        color: $primary-color;
    }
}

.bq-warning {
    border-left: 3px solid $warning-color;
    .bq-title {
        color: $warning-color;
    }
}

.bq-danger {
    border-left: 3px solid $danger-color;
    .bq-title {
        color: $danger-color;
    }
}

.bq-success {
    border-left: 3px solid $success-color;
    .bq-title {
        color: $success-color;
    }
}

// Z-levels
.z-depth-0 {
    box-shadow: none !important;
}
.z-depth-1 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}
.z-depth-sharp {
    box-shadow: 0 1px 1px rgba(0,0,0,0), 0 2px 2px rgba(0,0,0,0),0 4px 4px rgba(0,0,0,0.16),0 8px 8px rgba(0,0,0,0),0 16px 16px rgba(0,0,0,0.1);
    // box-shadow: 0 1px 1px rgb(0 0 0 / 0%), 0 2px 2px rgb(0 0 0 / 0%), 0 4px 4px rgb(0 0 0 / 16%), 0 8px 8px rgb(0 0 0 / 0%), 0 16px 16px rgb(0 0 0 / 10%);
}

// Custom grids
.row {
    $class: w !default;

    @for $i from 1 through 100 {
        .#{$class}-#{$i} {
            width: $i + 1%;
            padding-right: 15px;
            padding-left: 15px;

            @if $i == 20 {
                max-width: 50px;      
            }
        }
    }
}

// Custom margins
.mg- {

    // Bottom Margins
    @for $i from 1 through 100 {
        &bot-#{$i} {
            margin-bottom: $i + 1px !important;
        }
    }
    
    // Top Margins
    @for $i from 1 through 100 {
        &top-#{$i} {
            margin-top: $i + 1px !important;
        }
        
        &top-#{$i}-inverse {
            margin-top: -($i + 1px) !important;
        }
    }
    
    // Right Margins
    @for $i from 1 through 100 {
        &right-#{$i} {
            margin-right: $i + 1px !important;
        }
    }
    
    // Left Margins
    @for $i from 1 through 100 {
        &left-#{$i} {
            margin-left: $i + 1px !important;
        }
    }
}

// Custom opacity
.opacity-{
    @for $i from 1 through 100 {
        &#{$i} {
            opacity: calc($i / 100) !important;
        }
    }
}

.truncate  {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.non-selectable {
    pointer-events: none;
}

.mg-auto {
    margin: 0 auto !important;
}

$alignments: (
  left: left,
  right: right,
  center: center,
) !default;

@each $key, $val in $alignments {
    .text-align-#{$key} {
        text-align: $key;
    }
}

.z-index-1 {
    z-index: 1 !important;
}
.z-index-2 {
    z-index: 2 !important;
}
.z-index-3 {
    z-index: 3 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-pad-bot {
    padding-bottom: 0 !important;
}

.no-pad-top {
    padding-top: 0 !important;
}

.no-pad-right {
    padding-right: 0 !important;
}

.no-pad-left {
    padding-left: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: 0 !important;
}

.no-auto-height {
    min-height: auto !important;
}

.trim-text {
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: nowrap;
}

.bg-transparent {
    background-color: transparent !important;
}

.hoverable {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-unset {
    overflow: unset !important;
}

.no-select {
    user-select: none;
    pointer-events: none;
    opacity: 0.9;

    &.op-45 {
        opacity: .45;
    }
    
    &.op-60 {
        opacity: .60;
    }
}

.w-full {
    width: 100%;
    &.important {
        width: 100% !important;
    }
}

.no-select-disabled {
    user-select: none;
    pointer-events: none;
}

.no-box-shadow {
    box-shadow: none !important;
}

.hide {
    display: none !important;
}

.font-normal {
    font-weight: normal !important;
    font-size: initial !important;
}

.w-fit-content {
    width: fit-content !important;
}

.relative {
    position: relative;
    &-important {
        position: relative !important;
    }
}

.justify-center {
    justify-content: center;
}

.float {
    &-right {
        float: right !important;
    }

    &-left {
        float: left !important;
    }
}

@for $i from 1 through 50 {
    .br-#{$i} {
        
        @include border-radius(($i + 1px) !important)
    }
    
    .gap-#{$i} {
        gap: ($i + 1px) !important;
    }
}

$screens: (
    "small-down": $small-and-down,
    "medium-down": $medium-and-down,
    "medium-up": $medium-and-up,
    "large-up": $large-and-up,
) !default;

@each $key, $val in $screens {

    @media #{$val} {
        .ui.grid > .collapse-on-#{$key}.collapse-on-#{$key}.row > .column, .ui.collapse-on-#{$key}.grid > .column.grid > .column, .ui.collapse-on-#{$key}.grid > .column.row > .column, .ui.collapse-on-#{$key}.grid > .column:not(.row), .ui.collapse-on-#{$key}.grid > .row > .column, .ui.collapse-on-#{$key}.grid > .row > .wide.column, .ui.collapse-on-#{$key}.grid > .wide.column {
            width: 100% !important;
            box-shadow: none !important;
            padding: 1rem 1rem !important;
        }
    }

    .grid.collapse-on-#{$key} {
        @media #{$val} {
            width: auto;
            // margin-left: 0 !important;
            // margin-right: 0 !important;
            
            .column {
                width: 100% !important;
                margin: 0 !important;
            }

            .stack-on-#{$key} {
                display: block !important;
                .button {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: end;
}

.flex-1 {
    flex: 1;
}

.add-border-top {
    border-top: 1px solid color('grey', 'lighten-2');
}

.flex {
    display: flex;
    gap: 1rem;

    &.dir-column {
        flex-direction: column;
    }

    .flex-auto {
        flex: auto;
    }
}

.grey-border-top {
    border-top: 1px solid #e0e0e0;
}

.no-decoration {
    text-decoration: none;
    color: inherit;

    &:hover {
        // text-decoration: none;
        // color: inherit;
    }
}

.lowercase {
    text-transform: lowercase;
}