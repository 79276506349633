// 
// @Author: Keith

#{$base-app-class} {
  
  .pro-sidebar-inner {
    overflow: initial;
    // overflow-y: auto;
  }


  .ui.menu .ui.dropdown .menu > .active.item {
    font-family: "Quicksand-Bold" !important;
  }

  .top-navigation {
    margin: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    position: fixed;
    z-index: $top-nav-z-index;
    width: 100%;
    height: $top-nav-height;
    box-shadow: 0 1px 2px 0 $nav-border-color !important;
    font-family: "Quicksand-Regular" !important;
    // background-color: $nav-text-color !important;

    .item {
      color: $nav-text-color !important;

      &::before {
        background-color: $nav-border-color !important;
      }

      &.no-line {
        &::before {
          width: 0px;
        }
      }
    }

    .profile-settings-container {
      &:hover {
        // background-color: #1b8bbe;
        // cursor: pointer;
      }
    }

    .profile-dropdown, .dropdown-with-selected-icon {
      
      position: relative;

      .text {
        &[role=alert] {
          margin-left: 30px !important;
        }
      }

      .user.icon, .image.source {
        position: absolute;
      }

      &.no-line {
        &::before {
          width: 0px;
        }
      }

      .profile-menu {
        right: 10px !important;
        top: 80% !important;
      }
    }

    .logo-container {
      
      width: calc(#{$logo-container-size} - #{$sidebar-collapsed-width});
      padding-left: 0;
      img {
        height: $logo-container-image-size !important;
        // margin: 0 auto !important;
      }
    }

    .ham-container {
      width: $sidebar-collapsed-width;
      padding-right: 0;

      .bars, .close {
        margin: 0 !important;
      }
    }

    .org-dropdown-container {
      // width: $top-nav-org-dropdown-width;
      
      .visible.menu.transition {
        right: 10px !important;
        top: 80% !important;
        width: 250px;
        max-height: 400px;
        overflow-y: auto;
      }

      .ui.menu .ui.dropdown .menu > .item{
        @extend .trim-text;
      }
      
      [role=option].item{
        @extend .trim-text;
      }

      .dropdown {
        // margin-left: 40px;
        // position: relative;
        .selected-logo-container {
          position: absolute;
          left: -40px;
          top: 0;
        }
      }
      
      .dropdown.icon {
        margin-left: 7px !important;
      }

      &:hover {
        background-color: rgba(0,0,0,.03);
        cursor: pointer;
      }
    }

    .icon-only {
      font-size: $nav-icon-size;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
    
  .side-nav-with-content-container {
      height: 100%;
      padding-top: $top-nav-height;
  
      .side-navigation {
          margin: 0 !important;
          border: 0 !important;
          border-radius: 0 !important;
      }
  
      .content-area {
  
      }
  
      // Subsequest child trick
      hr ~ p {
  
      }
  }

  .pro-sidebar {
      padding-top: $top-nav-height;
      z-index: $side-bar-z-index;
      border-right: 1px solid $nav-border-color;
  }

  .sidebar-btn-wrapper {
      
      $button-line-height: 30px;

      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .sidebar-btn {
        padding: 1px 15px;
        border-radius: 40px;
        background: color('primary', 'lighten-1') !important;
        color: $white !important;
        text-decoration: none;
        margin: 0 auto;
        height: $button-line-height;
        display: flex !important;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: $side-nav-icon-size;
        
        span, svg {
          display: block;
          margin-left: 5px;
          font-size: 12px;
          line-height: $button-line-height;
        }

        &:hover {
          color: $highlight-color;
        }
      }
  }

  .pro-inner-item, .pro-menu-item {
    
    &.non-selectable {
      .pro-inner-item {
        padding-bottom: 0;
      }
    }

    .pro-icon > .icon {
      margin: 0;
      font-size: 0.9em;
      color: $nav-text-color;
    }
    
    &:hover {
      .pro-icon-wrapper {
        .pro-icon {
          animation: none !important;
        }
      }

      &:not(.no-hover) {
        // @extend .pro-inner-item.active;
        background-color: color('primary', 'lighten-3') !important;
        // @include  border-radius(5px !important);
        color: $nav-text-color !important;
      }

      .pro-item-content, .pro-item-content > a {
        color: $nav-text-color;
        line-height: initial;
      }
    }

    .pro-item-content, .pro-item-content > a {
      color: $nav-text-color;
      line-height: initial;
    }

    &.active {
      color: $highlight-active-color !important;
      background-color: color("primary", "lighten-3");
      position: relative;

      span {
        font-weight: bold;
        font-family: 'Quicksand-SemiBold';
      }

      .pro-item-content, .pro-item-content > a {
        color: $highlight-active-color !important;
      }
      
      .pro-inner-item {
        &::before {
          content: ' ';
          height: 100%;
          width: 5px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: color("primary", "base");
        }
        // border-left: 5px solid color("primary", "base");
      }

      // @include border-radius($default-border-radius);

      // border-top-right-radius: 5px;
      // border-bottom-right-radius: 5px;

      .pro-icon, .icon {
        color: color("primary", "base") !important;
      }

      .pro-item-content, .pro-item-content > a {
        color: $nav-text-color;
      }

    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;

      span {
        line-height: 1px;
        display: none;
      }
    }

    .hide-on-collapse {
      display: none;
    }
  }

  .pro-icon-wrapper {
    font-size: $side-nav-icon-size !important;

    width: auto !important;
    min-width: auto !important;
    height: auto !important;
    line-height: 20px !important;

    .pro-icon {
      display: inline !important;
      font-size: $side-nav-icon-size;
      align-items: normal !important;
    }
  }

  .pro-menu {
    // border-bottom: 1px solid lighten($nav-text-color, 20%);
  }

  .pro-sidebar-footer {
    border-top: none !important;
  }

  .secondary-sidebar {

    @extend .pro-sidebar;
    width: $builder-side-nav-width;
    min-width: $builder-side-nav-width;

    &.collapsed {
      width: 0px;
      min-width: 0px;
    }

    .pro-sidebar-inner {
      // @include scrollbars(5px, $nav-text-color);
    }

    .ui.input {
      width: 100%;
    }
    
    &.preview {
      width: $preview-builder-side-nav-width;
      min-width: $preview-builder-side-nav-width;
      border-left: 1px solid  $nav-border-color;
      background-color: $preview-bg-color;
      padding-top: $top-nav-height;
      // padding-top: $top-nav-height * 2;
      .pro-sidebar-content {
        padding: 0 7%;
        zoom: .8;
      }

      .smartphone {
        width: 89%;
        min-height: 400px;
        height: 654px;
        border-right-width: 17px;
        border-left-width: 17px;

        .s-content {
          height: 100%;
        }

        .footer, .message-view {
          zoom: 0.8;
        }

        .message-view {
          height: 100%;
        }
      }
    }

    background: $white;
    color: $nav-text-color;
    // @extend .z-depth-2;

    .pro-inner-list-item {
      background-color: #F3F3F3 !important;
      padding-left: 0 !important;

      > div {
        max-width: $builder-side-nav-width;
      }
    }

    .pro-arrow {
      border-color: $nav-text-color !important;
    }

    .pro-menu {
      border-bottom: 0;
      
      &.bordered {
        border-top: 1px solid #F3F3F3;
      }

      a {
        color: $nav-text-color;

        &:hover, &:active, &:visited, &:focus {
          color: color('primary', 'lighten-3') !important;
        }
      }
    }

    .pro-inner-item {
      padding: 20px 14px 20px 14px !important;
      border-bottom: 0.1rem solid color('grey', 'lighten-4');

      &:hover, &:active, &:visited, &:focus {
        color: color('primary', 'lighten-3') !important;
      }
    }

    .pro-sidebar-header {
      height: 50px !important;
      line-height: 50px !important;
      border-bottom: 0 !important;
      
      margin-top: 15px;
      margin-bottom: 20px;

      .secondary-nav-header-title {
        padding: 24px 24px 0;
        color: $nav-text-color;

        .icon {
          margin-right: 5px;
        }
      }
    }

    .text-with-line {
      position: relative;
      z-index: 1;
      width: 100%;
      text-align: center;

      &:before {
        border-top: 2px solid $nav-text-color;
        content: "";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%; left: 0; right: 0; bottom: 0;
        width: 95%;
        z-index: -1;
      }
  
      span { 
        /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
        background: $white; 
        padding: 0 15px;
      }
    }

    .builder-sn-title {
      transition: all 0.5s ease;
      color: $nav-text-color;

      .search-icon {
        float: right;
        &:before {
          content: "\f002";
          color: $nav-text-color !important;
        }
      }
    }

    .floating-cancel-icon {
      transition: all 0.5s ease;
      position: absolute;
      right: 15px;
      top: 30px;

      &:before {
        content: "\f00d" !important;
      }
    }
  }

  .description-container {
    background: $white;
    padding-left: 2em;
    padding-right: 2em;
    color: color('grey', 'base');
    margin-top: -15px;
    padding-bottom: 10px;
  }

  .pro-menu-item {
    font-size: 14px !important;

    &.open {
      .pro-arrow-wrapper > .pro-arrow {
        border-color: color('primary', 'base') !important;
      }
    }

    &.with-dropdown {
      width: 100%;

      .pro-inner-item.active > .pro-arrow-wrapper > .pro-arrow,
      &[aria-expanded="true"] > .pro-inner-item > .pro-arrow-wrapper > .pro-arrow {
        border-color: color('primary', 'base');
      }

      .pro-arrow-wrapper {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        .pro-arrow {
          display: inline-block;
          border-style: solid;
          border-color: color('grey', 'darken-1');
          border-width: 0 2px 2px 0;
          padding: 2.5px;
          vertical-align: middle;
          transition: transform 0.3s;
          transform: rotate(-45deg);

          &.active {
            border-color: color('primary', 'base');
          }
        }
      }

      .text {
        &[role=alert] {
          display: none;
        }
      }

      .menu {
        &.visible {
          margin: 0 !important;
          top: 8px !important;
          left: 98% !important;

          [role=option] a {
            &:hover {
              // color: $secondary-color;
            }
          }
        }

        .item {
          line-height: 30px;
        }

        &::after {
          top: 1.2em;
          display: none !important;
        }
      }
    }

    .icon.info.question.circle {
      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item, .app .secondary-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  // padding: 15px 35px 15px 14px;
  padding: 15px;
  padding-right: 30px;
  // padding-top: 16px;
  // padding-bottom: 16px;
}

.create-button-container {
  
  .pro-inner-item {
    padding-left: 15%;
    padding-right: 15%;
  }

  .create-button {
    width: 100%;
    background-color: white !important;
    color: $default-text-color !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    letter-spacing: 0.1em;
    
    &.collapsed {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../images/add_icon.svg);
      &:hover {
        background-image: url(../../images/add_icon.svg);
      }
    }

    span {
      font-weight: bold;
      font-family: 'Quicksand-Bold';
    }

    .icon.plus, .material-icons {
      color: $secondary-color;
    }
  }

  &.collapsed {
    
    .create-button {
      width: 30px;
      text-align: left;
      height: 30px;
      margin: 0;
      padding: 9px;
      @include vertical-align();
    }

    .pro-inner-item {
      padding: 0 !important;
      padding-left: 8px !important;
      // padding-right: 8px !important;
    }
  }
}

.create-button-container-dropdown {
  width: 100%;

  .menu {
    color: $default-nav-text-color;
    @include border-radius(5px !important);
    
    i.icon, span.material-icons {
      color: $nav-text-color;
    }

    .text {
      color: $nav-text-color;
    }

    .item {
      &:hover {
        .text, .icon {
          &:not(.trash) {
            color: color('primary', 'base') !important;
          }
        }

        .u-template {
          .trash.delete-icon {
            display: flex;
            // color: color('primary', 'base');
          }
        }
      }
    }

    &.transition.visible {
      margin-top: 0 !important;
      left: 14px !important;
      top: 140% !important;
      min-width: 200px !important;
      padding-top: 5px;
      padding-bottom: 5px;
      border: none;

      .divider {
        border-color: color('grey', 'lighten-3');
      }
    }
  }
}

.sidebar-floating-btn-wrapper {
  position: absolute;
  bottom: 5%;
  left: $sidebar-width + $sidebar-collapsed-width;
  z-index: 200;
  transition: all 0.5s ease;
  border-left: 1px solid #F3F3F3;
  
  &.bsn-opened.sn-opened {
    left: 2 * $sidebar-width;
  }
  
  &.bsn-collapsed.sn-collapsed {
    left: $sidebar-collapsed-width;
  }
  
  &.bsn-collapsed.sn-opened {
    left: $sidebar-width;
  }

  .button {
    padding: 10px !important;
    background: $white !important;
    color: $nav-text-color !important;
    border-radius: 0 !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;

    &:hover {
      color: $secondary-color !important;
    }
  }
}

.preview-sidebar-floating-btn-wrapper {
  
  &.visible-md-down {
    display: none;
  }

  display: block;
  position: absolute;
  top: 15%;
  right: $preview-builder-side-nav-width;
  z-index: 200;
  transition: all 0.5s ease;
  border-right: 1px solid #F3F3F3;
  
  &.open {
    right: $preview-builder-side-nav-width;
  }
  
  &.closed {
    right: 0;
  }

  .button {
    margin: 0;
    padding: 10px !important;
    background: $white !important;
    color: $nav-text-color !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;

    &:hover {
      color: $secondary-color !important;
    }
  }
}

.border-bot-1 {
  // border-bottom: 0.1rem solid color('grey', 'lighten-3');
}

.no-hover {
  .pro-inner-item {
    &:hover {
      ::before {
        content: none;
      }
    }
  }
}

.builder-side-nav-popup {
  left: $sidebar-collapsed-width !important;

  .content {
    color: color('grey', 'base') !important;
    font-size: 80%;
  }
}

.template-info-popup {
  left: $sidebar-collapsed-width !important;
}

.u-template {
  max-width: 90%;
  @extend .trim-text;
  // position: relative;
  .trash.delete-icon {
    top: 0;
    margin: 0;
    width: 20px;
    right: 20px;
    height: 42px;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: none;

    &:hover {
      color: color('primary', 'base');
    }
  }
}

// SIDE-EFFECT: this is not necessary for Chromium, but fixes  a bug on safari  where <Draggable/>s goes behind container since
// its parent resides in a container that has an overflow
.app.main-is-dragging {
  .secondary-sidebar {
    .pro-sidebar-inner {
      overflow-y: hidden;
    }
  }
}