@use "sass:color";
body.police-tape {
    // @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');

    $dark-grey: #111111;
    $lighter-grey: #27292d;
    $yellow: #e2bb2d;
    $yellow2: #B79A2F;

    .pro-sidebar, .ham-container {
        display: none !important;
    }

    .logo-container {
        padding-left: 20px !important;
    }

    min-height: 100%;
    background-color: $dark-grey;
    font-family: "Quicksand-Thin";
    overflow: hidden;
    text-transform: uppercase;

    .police-tape {
        background-color: $yellow;
        background: linear-gradient(180deg, color.adjust($yellow, $lightness: 20%) 0%, $yellow 5%, $yellow 90%, color.adjust($yellow, $lightness: 5%) 95%, color.adjust($yellow, $lightness: -50%) 100%);
        padding: 0.125em;
        font-size: 2em;
        text-align: center;
        white-space: nowrap;
    }

    .police-tape--1 {
        transform: rotate(10deg);
        position: absolute;
        top: 40%;
        left: -5%;
        right: -5%;
        z-index: 2;
        margin-top: 0;

        a {
            text-decoration: underline;
        }
    }

    .police-tape--2 {
        transform: rotate(-8deg);
        position: absolute;
        top: 50%;
        left: -5%;
        right: -5%;
    }
}

body.zagged-403 {
    .pro-sidebar, .ham-container {
        display: none !important;
    }

    .logo-container {
        padding-left: 20px !important;
    }

    main {
        $font: 'Poppins', sans-serif;
        // $yelling-color: #f6d200;
        $yelling-color-1: #181617;
        // $yelling-bubbles: color('grey', 'base');
        $yelling-bubbles: $secondary-color;
        $yelling-color: color.adjust($primary-color, $lightness: 30%);
        // $yelling-color: $primary-color;
        // $yelling-color-1: $primary-color;

        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: $font;
        // background-image: linear-gradient(45deg, $yelling-color 25%, $yelling-color-1 25%, $yelling-color-1 50%, $yelling-color 50%, $yelling-color 75%, $yelling-color-1 75%, $yelling-color-1 100%);
        // background: lighten($primary-color, 50);

        .pro-sidebar, .ham-container {
            display: none !important;
        }

        .logo-container {
            padding-left: 20px !important;
        }

        .default-content-area {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .helper-text {
            .help-link {
                text-decoration: underline;
            }
            position: absolute;
            top: 100px;
            color: $primary-color;
        }

        h1 {
            text-transform: uppercase;
            background: repeating-linear-gradient(45deg,
                    $yelling-color,
                    $yelling-color 10px,
                    $yelling-color-1 10px,
                    $yelling-color-1 20px);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            /*animation: move 5s ease infinite;*/
            // font-size: 384px;
            font-size: 200px;
            margin: 0;
            line-height: .7;
            position: relative;

            &:before,
            &:after {
                content: "Blocked";
                background-color: $yelling-color;
                color: $yelling-color-1;
                border-radius: 10px;
                font-size: 35px;
                position: absolute;
                padding: 31px;
                text-transform: uppercase;
                font-weight: bold;
                -webkit-text-fill-color: $yelling-color-1;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(20deg);
            }

            &:before {
                content: "";
                padding: 70px 130px;
                background: repeating-linear-gradient(45deg, $yelling-color, $yelling-color 10px, $yelling-color-1 10px, $yelling-color-1 20px);
                box-shadow: 0px 0px 10px $yelling-color-1;
            }

            & span:before,
            & span:after {
                content: "";
                width: 8px;
                height: 8px;
                background: $yelling-bubbles;
                color: $yelling-bubbles;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                margin: auto;
                top: 20%;
                z-index: 3;
                box-shadow: 0px 60px 0 0px;
            }

            span:before {
                left: 39%;
                transform: rotate(22deg);
                top: -87%;
            }

            span:after {
                right: 36%;
                transform: rotate(22deg);
                top: 3%;
            }
        }
    }

}