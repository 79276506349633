[dir*="rtl"] {
    #{$base-app-class} {

        header {
            h1 {
                transform: translateX(20px);
            }
        }

        .block {
            direction: rtl;

            >span {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        .sidebar-btn-wrapper {
            .sidebar-btn {
                span {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }

        .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper,
        .secondary-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
            margin-left: 10px;
            margin-right: 0;
        }

        .pro-sidebar.collapsed .pro-inner-item .pro-icon-wrapper {
            margin-left: 0px !important;
        }

        .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item,
        .secondary-sidebar .pro-menu .pro-menu-item>.pro-inner-item,
        .secondary-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
            padding: 15px;
            padding-left: 30px;
            text-align: right;
        }
        
        .pro-sidebar.collapsed .pro-inner-item {
            padding-left: 17px !important;
        }

        .pro-menu-item.active .pro-inner-item::before {
            left: unset;
            right: -1px;
        }

        .pro-menu-item.with-dropdown .pro-arrow-wrapper {
            right: auto;
            left: 20px;
        }

        .pro-menu-item.with-dropdown .pro-arrow-wrapper .pro-arrow {
            transform: rotate(128deg);
        }

        .pro-menu-item.with-dropdown .menu.visible {
            left: auto !important;
            right: 98%;
        }

        // .top-navigation .item.no-line::before {
        //     width: 0px;
        // }

        .top-navigation .ham-container,
        .builder-top-navigation .ham-container {
            padding: 0.92857143em 1.14285714em;
        }

        .ui.dropdown>.text>img {
            margin-right: auto;
            margin-left: 0.78571429rem;
        }

        .top-navigation .org-dropdown-container .dropdown.icon,
        .builder-top-navigation .org-dropdown-container .dropdown.icon {
            margin-left: auto !important;
            margin-right: 7px !important;
        }

        .top-navigation .profile-dropdown .profile-menu,
        .builder-top-navigation .profile-dropdown .profile-menu {
            left: 10px !important;
            right: auto !important;
        }

        .ui.menu:not(.vertical) .right.item,
        .ui.menu:not(.vertical) .right.menu {
            margin-left: initial !important;
            margin-right: auto !important;
        }

        // .locale-switcher {
        //     &.no-line {
        //         &::before {
        //             width: 1px;
        //         }
        //     }
        // }

        .ui.menu .item>i.icon {
            // margin: 0 0 0 0.35714286em;
        }

        .ui.menu .item>i.dropdown.icon {
            margin: 0 1em 0 0;
        }

        .menu .item i.flag:not(.icon) {
            margin: 0 0 0 0.5em;
        }

        .top-navigation .profile-dropdown .text[role=alert],
        .builder-top-navigation .profile-dropdown .text[role=alert] {
            margin-left: auto !important;
            margin-right: 25px !important;
        }

        @include mq_medium_down {
            .top-navigation .profile-dropdown {

                .text,
                .dropdown.icon {
                    display: none !important;
                }
            }
        }

        .create-button-container-dropdown .menu.transition.visible {
            left: 0 !important;
            right: 14px;
        }

        .create-button-container.collapsed {
            .create-button {
                text-align: right;
            }

            .pro-inner-item {
                padding: 0 !important;
                padding-right: 8px !important;
            }
        }

        .pagination .page-nav .primary-input {
            margin-left: 20px;
            margin-right: auto;
        }

        .pagination .ui.inline.dropdown {
            margin-right: 5px;
            margin-left: auto;
        }

        .pagination .dropdown.icon {
            margin-right: 10px !important;
            margin-left: auto !important;
        }

        .pagination .page-nav .pagers i.left {
            margin-left: 30px;
            margin-right: 20px;
        }

        .page-section .section-header .icon {
            margin-right: auto;
            margin-left: 10px;
        }

        .super-t tbody tr .actions .action-group,
        .super-t thead tr .actions .action-group {
            right: auto;
            left: 10px;
        }

        .super-t tbody tr .actions .action-group .button:not(:last-child),
        .super-t tbody tr .actions .action-group .transformed-content-container .icons-container button:not(:last-child),
        .super-t thead tr .actions .action-group .button:not(:last-child),
        .super-t thead tr .actions .action-group .transformed-content-container .icons-container button:not(:last-child),
        .transformed-content-container .icons-container .super-t tbody tr .actions .action-group button:not(:last-child),
        .transformed-content-container .icons-container .super-t thead tr .actions .action-group button:not(:last-child) {
            padding: .78571429em 1.5em .78571429em;
            padding-left: 0;
        }

        // Custom margins
        .mg- {

            // Right Margins
            @for $i from 1 through 100 {
                &right-#{$i} {
                    margin-left: $i + 1px !important;
                    margin-right: 0 !important;
                }
            }

            // Left Margins
            @for $i from 1 through 100 {
                &left-#{$i} {
                    margin-right: $i + 1px !important;
                    margin-left: 0 !important;
                }
            }
        }

        .template .name {
            margin-left: 0;
            margin-right: 5px;
        }

        .components-container .component-droppable .draggable-component {
            padding: 0 !important;
            padding-left: 15px !important;
        }

        .rdw-dropdown-carettoopen,
        .rdw-dropdown-carettoclose {
            left: 10%;
            right: auto;
        }

        .right-align {
            text-align: left;
        }

        .left-align {
            text-align: right;
        }

        .dropdown-float-right {
            text-align: left;
            padding-left: 30px !important;
            padding-right: 0 !important;
        }

        .info-icon.morphed.in-label,
        .user-template-icon.morphed.in-label {
            margin-right: 10px;
            margin-left: 0;
        }

        .default-content-area>.page .header-container .update-cover-button {
            left: auto;
            right: 15px;
        }

        .default-content-area>.page .header-container .cover-image .profile-image {
            left: auto;
            right: calc(88% - 75px);
        }

        .default-content-area > .page .header-container .update-profile-button {
            right: auto;
            left: -11px;
        }

        .input-container .PhoneInput .PhoneInputCountry {
            margin-right: 10px;
            margin-left: var(--PhoneInputCountrySelect-marginRight);
        }

        .input-container .PhoneInput .PhoneInputCountrySelectArrow {
            margin-left: 0;
            margin-right: var(--PhoneInputCountrySelectArrow-marginLeft);
        }

        .ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
            right: auto;
        }

        .ui.icon.input>i.icon {
            right: auto;
        }

        .ui.icon.input > input {
            padding: .67857143em 1em !important;
        }

        .float {
            &-right {
                float: left !important;
            }
        
            &-left {
                float: right !important;
            }
        }

        .chart-container.summary-chart {
            background-color: $bg-charts-gray !important;
        }

        .super-t .batch-actions-dropdown .menu [role=option] .text {
            margin-left: 0;
            margin-right: 10px;
        }

        .table-container .child .checkbox::after {
            content: "↵";
        }

        @media (max-width: $breakpoint-md) {
            .btn-toggle {
                margin-left: auto;
            }
        }
    }
}