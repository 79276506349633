@use "sass:map";
.msts {
	user-select: none;
	cursor: default;
}

.msts_disabled {
}

.msts__heading {
	display: flex;
}

.msts__subheading {
	display: flex;
}

.msts__body {
	display: flex;
}

.msts__footer {
	display: flex;
}

.msts__side {
	width: 50%;
}

.msts__side_available {
}

.msts__side_selected {
}

.msts__side_controls {
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.msts__list {
	padding: 0;
	margin: 0;
}

.msts__list-item {
	list-style-type: none;
	cursor: pointer;
}

.msts__list-item_disabled {
	cursor: default;
}

.msts__list-item_highlighted {
}

.msts__control {
}

.msts__control_select-all {
}

.msts__control_deselect-all {
}

.msts__filter-input {
}

/* Example theme */
.msts_theme {
	// border: 1px solid silver;

	.msts__heading {
		.msts__side {
			padding: 5px;
			text-align: center;
			color: #fff;
			font-weight: bold;
		}

		.msts__side_available {
			background-color: #9b59b6;
		}

		.msts__side_selected {
			background-color: #2ecc71;
		}
	}

	.msts__subheading {
		.msts__side_filter {
			padding: 5px;
		}
	}

	.msts__footer {
		.msts__side {
			padding: 5px 15px;
			background-color: #ecf0f1;
			font-size: 0.75em;
			color: #7f8c8d;
			text-align: right;
		}
	}

	.msts__list {
		height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.msts__list-item {
		padding: 5px 10px;
		transition: background-color ease-in 0.1s, color ease-in 0.1s;
		color: map.get($grey, "darken-4");

		@include hover {
			// font-family: "Quicksand-SemiBold";
			background-color: color('primary', 'lighten-3');
		}

		&_disabled {
			background-color: #ecf0f1;
			color: #7f8c8d;

			&:hover {
				background-color: #ecf0f1;
				color: #7f8c8d;
			}
		}

		&_highlighted {
			background-color: color('primary', 'lighten-4');
			font-family: "Quicksand-SemiBold";
			@include hover {
				background-color: color('primary', 'lighten-4');
			}
		}
	}

	.msts__control {
		border: none;
		background: none;
		cursor: pointer;
		padding: 10px 3px;
		color: #bdc3c7;
		transition: color ease 0.15s;

		&:hover {
			color: #95a5a6;
		}

		&[disabled] {
			color: #ecf0f1;
		}
	}

	.msts__body {
		.msts__side_available, .msts__side_selected {
			// padding: 1em 1em;
			border-radius: .28571429rem;
			border: 1px solid rgba(34,36,38,.15);
		}
	}

	.no-padding {
		.input-container {
			&:first-child {
				padding-right: 1em;
				padding-left: 1em;
				padding-top: 1em;
			}

			&:not(:first-child) {
				padding-right: 1em;
				padding-left: 1em;
			}
		}
	}
}

.msts_theme.msts_disabled {
	background-color: #ecf0f1;
}

.button {
	&.icon-only-arrow {
		background: transparent !important;
		color: $primary-color !important;
		padding: 0 !important;
		box-shadow: none !important;

		@include hover {
			background: transparent !important;
			color: $primary-color !important;
		}
	}
}

.equalize-height {
	margin-top: 80px !important;
}

.contents-container {
	border-top: 1px solid rgba(34,36,38,.15);
	position: relative;
}

.control-button {
	@include flexbox();

	.button {
		box-shadow: none !important;
		@include vertical-align();
		background: transparent !important;
		color: $secondary-color !important;
		width: 50%;
		padding-left: 5px !important;
		padding-right: 5px !important;

		.material-icons {
			margin-right: 5px;
		}

		&.clear-selection {
			color: color("red", "base") !important;
		}

		@include hover {
			background: transparent !important;
			color: $primary-color !important;
		}
	}
}


.label-container {
	position: absolute;
	bottom: -20px;
	right: 15px;
}