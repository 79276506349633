// Fonts Path
$font-path: "./fonts/quicksand/" !default;
$quicksand-font-path: "./fonts/quicksand/" !default;

// Fonts
@font-face {
    font-family: "Quicksand-Thin";
    src: local(Quicksand Thin),
    url("#{$quicksand-font-path}Quicksand-Light.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Quicksand-Light";
    src: local(Quicksand Light),
    url("#{$quicksand-font-path}Quicksand-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Quicksand-Regular";
    src: url("#{$quicksand-font-path}Quicksand-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Quicksand-Medium";
    src: url("#{$quicksand-font-path}Quicksand-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Quicksand-SemiBold";
    src: url("#{$quicksand-font-path}Quicksand-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Quicksand-Bold";
    src: url("#{$quicksand-font-path}Quicksand-Bold.ttf") format("truetype");
    font-weight: 700;
}

body {
    font-family: 'Quicksand-Regular', sans-serif !important;
    font-weight: normal !important;
    color: $default-nav-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Quicksand-Bold" !important;
    // font-weight: normal !important;
}

b,
strong {
    font-family: "Quicksand-Bold" !important;
    font-weight: bold !important;
}

input,
textarea {
    font-family: "Quicksand-Medium" !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;

    @include placeholder {
        color: color("grey", "lighten-1");
    }
}

.ui.button {
    font-family: "Quicksand-Medium" !important;
    font-weight: 500 !important;
}

p.label,
.ui.breadcrumb .active.section, 
.ui.dropdown .menu .active.item {
    font-family: "Quicksand-Bold" !important;
}

.ff-bold {
    font-family: "Quicksand-Bold" !important;
    font-weight: bold;
}

.ui.label, label.label, .ui.form .field > label {
    
    &.ff-light {
        font-family: "Quicksand-Medium" !important;
    }

    font-family: "Quicksand-Bold" !important;
}

.ui.modal > .header {
    font-family: "Quicksand-Bold" !important;
}

.ui.table, body {
    color: $default-text-color !important;
}

.pro-sidebar {
    color: $default-nav-text-color;
    font-family: 'Quicksand-Regular';
}