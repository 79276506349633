@use "sass:color";
@use "sass:list";
@use "sass:map";
/*********************
  Mixins
**********************/

@mixin hover {
    @if $enable-hover-media-query {
        // See Media Queries Level 4: http://drafts.csswg.org/mediaqueries/#hover
        // Currently shimmed by https://github.com/twbs/mq4-hover-shim
        @media (hover: hover) {
            &:hover {
                @content
            }
        }
    }
    @else {
        &:hover {
            @content
        }
    }
}

@mixin hover-focus {
    @if $enable-hover-media-query {
        &:focus {
            @content
        }
        @include hover {
            @content
        }
    }
    @else {
        &:focus,
        &:hover {
            @content
        }
    }
}

@mixin plain-hover-focus {
    @if $enable-hover-media-query {
        &,
        &:focus {
            @content
        }
        @include hover {
            @content
        }
    }
    @else {
        &,
        &:focus,
        &:hover {
            @content
        }
    }
}

@mixin hover-focus-active {
    @if $enable-hover-media-query {
        &:focus,
        &:active {
            @content
        }
        @include hover {
            @content
        }
    }
    @else {
        &:focus,
        &:active,
        &:hover {
            @content
        }
    }
}

@mixin border-radius($args) {
    -webkit-border-radius: $args;
    -moz-border-radius: $args;
    -ms-border-radius: $args;
    -o-border-radius: $args;
    border-radius: $args;
}

@mixin border-left-radius($args) {
    -webkit-border-top-left-radius: $args;
    -moz-border-top-left-radius: $args;
    -ms-border-top-left-radius: $args;
    -o-border-top-left-radius: $args;
    border-top-left-radius: $args;
    
    -webkit-border-bottom-left-radius: $args;
    -moz-border-bottom-left-radius: $args;
    -ms-border-bottom-left-radius: $args;
    -o-border-bottom-left-radius: $args;
    border-bottom-left-radius: $args;
}

@mixin border-right-radius($args) {
    -webkit-border-top-right-radius: $args;
    -moz-border-top-right-radius: $args;
    -ms-border-top-right-radius: $args;
    -o-border-top-right-radius: $args;
    border-top-right-radius: $args;
    
    -webkit-border-bottom-right-radius: $args;
    -moz-border-bottom-right-radius: $args;
    -ms-border-bottom-right-radius: $args;
    -o-border-bottom-right-radius: $args;
    border-bottom-right-radius: $args;
}

@mixin border-top-radius($args) {
    -webkit-border-top-right-radius: $args;
    -moz-border-top-right-radius: $args;
    -ms-border-top-right-radius: $args;
    -o-border-top-right-radius: $args;
    border-top-right-radius: $args;
    
    -webkit-border-top-left-radius: $args;
    -moz-border-top-left-radius: $args;
    -ms-border-top-left-radius: $args;
    -o-border-top-left-radius: $args;
    border-top-left-radius: $args;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &::-ms-placeholder {
        @content;
    }
    &::placeholder {
        @content;
    }
}

// New mixins
@mixin make-button($name, $color) {
    .btn-#{$name} {
        background-color: $color;
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: color.adjust($color, $lightness: 5%) !important;
        }
        &.dropdown-toggle {
            background-color: $color!important;
            &:hover,
            &:focus {
                background-color: color.adjust($color, $lightness: 5%) !important;
            }
        }
    }
}

@mixin make-outline-button($name, $color) {
    .btn-outline-#{$name} {
        border: 2px solid $color;
        color: $color !important;
        background-color: transparent;
        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &.active {
            background-color: transparent;
            color: $color;
            border-color: $color;
        }
    }
}

@mixin make-gradient-button($name, $value) {
    .btn.#{$name}-gradient {
        background: linear-gradient(40deg, map.get($value, start), map.get($value, end));
        transition:.5s ease;
        &:hover,
        &:focus,
        &:active,
        &:active:focus
        &.active {
            background: linear-gradient(40deg, color.adjust(map.get($value, start), $lightness: 5%), color.adjust(map.get($value, end), $lightness: 5%));
        }
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    @include border-radius($border-radius);
}


@mixin transition($transition...) {
    @if $enable-transitions {
        @if list.length($transition) == 0 {
            transition: $transition-base;
        } @else {
            transition: $transition;
        }
    }
}

@mixin transform($args) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map.get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Flexbox
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin align($align) {
    -webkit-flex-align: $align;
    -ms-flex-align: $align;
    -webkit-align-items: $align;
    align-items: $align;
}

@mixin justify-content($val) {
    -webkit-justify-content: $val;
    justify-content: $val;
}

@mixin vertical-align($important: !important, $align: center) {
    
    display: -webkit-box $important;
    display: -webkit-flex $important;
    display: -ms-flexbox $important;
    display: flex $important;
    -webkit-box-align: $align $important;
    -webkit-align-items: $align $important;
    -ms-flex-align: $align $important;
    align-items: $align $important;
}

// ============================================ Scrollbar Mixin ======================================
@mixin scrollbars($size, $foreground-color, $background-color: color.mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }
    
    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    
    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

// ============================================ Responsive Mixins ======================================
@mixin mq_small_down() {
    @media #{$small-and-down} {
      @content;
    }
}
  
@mixin mq_medium_down() {
    @media #{$medium-and-down} {
      @content;
    }
}
  
@mixin mq_medium_up() {
    @media #{$medium-and-up} {
      @content;
    }
}
  
@mixin mq_large_up() {
    @media #{$large-and-up} {
      @content;
    }
}

@mixin hr-line-indicator($color: color("primary", "base")) {
    &::before {
        visibility: hidden;
        opacity: 0;
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 5px;
        top: 0;
        background: transparent;
        border-top-left-radius: $default-border-radius;
        border-bottom-left-radius: $default-border-radius;
        border-left: 5px solid $color;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    }
}

@mixin hr-line-indicator-activate($color: color("primary", "base"), $important: !important) {
    &::before {
        border-left: 5px solid $color;
        visibility: visible $important;
        opacity: 1 $important;
        transition: opacity 0.5s linear;
    }
}