@use "sass:color";
@use "sass:map";
@use "sass:meta";

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$body-bg-color: color("white", "base") !default;


:root {

  // Calendar Customization
  $f-calendar-bg: color('primary', 'base');
  $f-calendar-active-bg: color('primary', 'lighten-4');

  --fc-button-bg-color: #{$f-calendar-bg};
  --fc-button-border-color: #{$f-calendar-bg};
  --fc-button-hover-bg-color: #{$f-calendar-active-bg};
  --fc-button-hover-border-color: #{$f-calendar-active-bg};
  --fc-button-active-bg-color: #{$f-calendar-active-bg};
  --fc-button-active-border-color: #{$f-calendar-active-bg};

  --template-bg-color: #{$template-bg-color};
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100%;
  color: $body-bg-color;
}

#root {
  height: 100%;
}

#{$base-app-class} {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  main {
    $content-bg-color: #F3F3F3 !default;
    $builder-content-bg-color: #F3F3F3;
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    margin-top: $top-nav-height;
    background-color: $content-bg-color;

    &.message-builder {
      background-color: $builder-content-bg-color;
      padding-left: 1%;
      padding-right: 1%;
      // margin-top: $top-nav-height * 2;
      margin-top: $top-nav-height;
    }

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      // h1 {
      //   display: flex;
      //   align-items: center;
      //   transform: translateX(-20px);
      // }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
  }
}

.no-auth {

  $container-height: 400px !default;
  $container-width:  50rem !default;

  height: 100% !important;
  margin: 0 !important;
  background-color: $bg-color;

  .card-layout {
    max-width: $container-width;

    // &.invitation {
    //   max-width: 60rem;
    // }

    .segment {
      &.custom {
        @include border-radius($default-border-radius);
        border: none;
        @extend .z-depth-1;
        overflow: hidden;
      }
    }

    .button {
      &:not(.icon-only) {
        background-color: color("primary", "base");
        color: $white;
        @extend .z-depth-1;

        @include hover {
          color: color.adjust($white, $lightness: 50%);
          background-color: color("primary", "lighten-3");
          @extend .z-depth-2;
        }
      }
    }

    .split-container {
      min-height: $container-height;

      .left-side {
        @include vertical-align(!important);
        @extend .no-margin;
        background: color('primary', 'base');

        .image {
          width: 100px;
          margin: 0 auto;
        }
      }

      .right-side {
        @include vertical-align();
        @extend .no-margin;
      }

      .welcome-header, .description-text {
        text-align: left;
      }
    }
  }
}

body {
  &.login, &.invite, &.no-link, &.link-calendar {
    .no-auth {
      background: $white;
    }
  }
  

  &.loading .data-init-screen, &:has(.full-ui-block) {
    
    background: $white;

    .loader {
      &::before, &::after {
        display: none;
      }
    }
  }
}

.badge {

  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  @include border-radius(15px);

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.primary-input {
  border: $primary-color 1px solid;
  padding: 10px;
  @include border-radius(5px);
}

.primary-icon {
  color: $primary-color !important
}

.pad-bot-15 {
  padding-bottom: 15px;
}

.pad-top-15 {
  padding-top: 15px;
}

.pad-bot-20 {
  padding-bottom: 20px;
}

.pad-top-20 {
  padding-top: 20px;
}

.vertical-align {
  @include vertical-align();

  &.justify-right {
    justify-content: flex-end;
  }
  
  &.justify-left {
    justify-content: flex-start;
  }
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.center-align-block {
  margin: 0 auto;
}

.react-switch {
  &.active {
    &:not(.disabled)  {
      .react-switch-handle {
        background: $secondary-color !important;
      }
    }

    &.disabled {
      .react-switch-handle {
        background: map.get($grey, "lighten-1") !important;
      }
    }
  }

  .react-switch-bg {
    background: color.adjust($secondary-color, $lightness: 10%) !important;
  }

  &.disabled {
    .react-switch-bg {
      background: color("grey", "lighten-2") !important;
    }
  }
  
  &:not(.active) {
    .react-switch-bg {
      background: map.get($grey, "lighten-1") !important;
    }
  }
}

.custom-switch {
  max-width: 300px;
  @include justify-content(center);
  
  span, p, label {
    &.caption {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
  }

  p, label {
    &.caption {
      font-size: inherit;
    }
  }

  .s-icon {
    font-size: 20px;
    color: $primary-color;

    &.publish {
      margin: 0;
      display: inline-table;
    }
  }

  &.no-justification {
    @include justify-content(left);
  }
}

.label {
  color: $default-text-color;
}

.form {

  .error.field {
    label.label {
      color: #9f3a38 !important;
    }
  }
  
  .warning {
    input[type=text], .input.ui.PhoneInput {
      border-color: color('secondary', 'base') !important;
    }
    
    &.field {
      label.label {
        color: color('secondary', 'base') !important;
      }
    }

    .warning-label {
      color: color('secondary', 'base') !important;
    }
  }

  .label {
    
    &.primary {
      color: color('primary', 'base') !important;  
    }

    color: $default-text-color !important;
  }
}

.button {

  &.all-caps {
    text-transform: uppercase !important;
  }

  text-transform: capitalize !important;

  &.tertiary {
    color: $secondary-color !important;
    background-color: transparent !important;
    
    &.white {
      color: $white !important;

      @include hover {
        background-color: color.adjust($secondary-color, $lightness: 10%) !important;
        color: $white !important;
      }
    }

    @include hover {
      background-color: color.adjust($secondary-color, $lightness: 35%) !important;
    }
  }

  &.primary {
    background-color: $secondary-color !important;
    color: $white !important;

    &.solid {
      color: $white !important;
      background-color: $primary-color !important;

      @include hover {
        background-color: color.adjust($primary-color, $lightness: 10%) !important;
        box-shadow: none !important;
        color: $white !important;
      }
    }

    &.danger {
      color: $white !important;
      background-color: color('red', 'base') !important;
      box-shadow: 0 0 0 1px color('red', 'base') inset !important;

      @include hover {
        box-shadow: 0 0 0 1px color.adjust(color('red', 'base'), $lightness: 10%) inset !important;
        background-color: color.adjust(color('red', 'base'), $lightness: 10%) !important;
      }
    }

    @include hover {
      background-color: color.adjust($secondary-color, $lightness: 10%) !important;
    }
  }
  
  &.calendar-nav {
    background-color: $primary-color !important;
    color: $white !important;

    &.active {
      background-color: lighten($primary-color, 10%) !important;
    }

    @include hover {
      background-color: lighten($primary-color, 10%) !important;
    }
  }

  &.link-calendar {
    min-width: 250px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      text-transform: none;
      color: $black;
    }
  }
  
  &.secondary {

    &.danger {
      color: color('red', 'base') !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px color('red', 'base') inset !important;

      @include hover {
        box-shadow: 0 0 0 1px color.adjust(color('red', 'base'), $lightness: 20%) inset !important;
        color: color.adjust(color('red', 'base'), $lightness: 20%) !important;
      }
    }
    

    &.black {
      color: $black !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px $black inset !important;

      @include hover {
        box-shadow: 0 0 0 1px color.adjust($black, $lightness: 20%) inset !important;
        color: color.adjust($black, $lightness: 20%) !important;
      }

      &.disabled {
        box-shadow: 0 0 0 1px color("gray", "base") inset !important;
      }
    }

    &-upgrade-required {
      color: color('grey', 'base') !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px color('grey', 'base') inset !important;

      @include hover {
        box-shadow: 0 0 0 1px color.adjust(color('grey', 'base'), $lightness: 20%) inset !important;
        color: color.adjust(color('grey', 'base'), $lightness: 20%) !important;
      }
    }
    
    &.white {
      color: $white !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px $white inset !important;

      @include hover {
        background-color: color.adjust($secondary-color, $lightness: 10%) !important;
        box-shadow: none !important;
        color: $white !important;
      }
    }

    background-color: transparent !important;
    color: $primary-color !important;
    box-shadow: 0 0 0 1px $primary-color inset !important;

    @include hover {
      box-shadow: 0 0 0 1px color.adjust($primary-color, $lightness: 20%) inset !important;
      color: color.adjust($primary-color, $lightness: 20%) !important;
    }
  }

  &.transparent {
    &.disabled {
      color: map.get($grey, "base") !important;
    }
  }
  
  &.secondary, &.primary {
    &.disabled {
      background-color: map.get($grey, "base") !important;
    }
  }
  
  &.transparent {
    @include hover {
      // background-color: map-get($grey, "lighten-4") !important;
    }
  }

  &.add-component {
    // margin-top: 30px !important;
    width: 100% !important;
    text-align: center !important;
    height: 90px !important;
    background: transparent !important;
    // color: #bdbdbd !important;
    border: .15em dashed #bdbdbd !important;
    font-family: 'Quicksand-SemiBold';
    font-weight: normal;
    cursor: default;
    text-transform: none !important;
    .icon {
      font-size: 30px;
    }
  }

  @include hover {
    // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    // background-color: map-get($grey, "lighten-4") !important;
  }
}



.color-secondary {
  background-color: color.adjust($secondary-color, $lightness: 25%) !important;
  color: $black !important;

  &-light {
    background-color: color.adjust($secondary-color, $lightness: 30%) !important;
    color: $primary-color !important;
  }
}

.color-primary {
  background-color: $default-tag-color !important;
  color: $black !important;

  &-light {
    background-color: color.adjust($default-tag-color, $lightness: 1%) !important;
    color: $primary-color !important;
  }
}

table.primary-table {

  .sortable, .pointer {
    cursor: pointer !important;
  }

  .pointable  {
    &:hover {
      background-color: color('primary', 'lighten-3');
    }
  }

  thead {
    tr {
      th {
        // padding: 20px !important
        padding: 15px .78571429em 15px .78571429em !important;
        width: 10%;
      }
    }
  }
  
  tbody {
    tr {
      td {
        cursor: default;
        padding: .58571429em .78571429em !important;
      }
    }
  }
}

.pagination-container {
  margin-top: 10px;
}

.filters-container {
  margin-top: 20px !important;
}

.page-caption {
  margin-top: 10px !important;
}

.selected-logo-container {
  padding: 0 10px 0 0 !important;
  
  .logo-holder {
    width: 30px;
    height: 30px;
    border-radius: 20%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.material-icons {
  &.active-icon {
    color: $primary-color;
  }

  &.disabled-icon {
    color: map.get($grey, "lighten-1");
  }
}

.custom-checkbox-icon {
  @extend .vertical-align;
  .icon-container {
    width: 7%;

    .material-icons {
      font-size: 20px;
    }
    
    .info-icon {
      color: $primary-color;
      font-size: 20px;
    }

    .price-container {
      color: $primary-color;
    }
  }

  .usage-container {
    width: 20%;
    text-align: right;
  }

  .content-container {
    width: 63%;

    h2, h3, h4 {
      margin: 0;
      font-size: medium;
      padding-bottom: 5px;
    }

    p.no-margin, p.note {
      font-size: 11px;
    }
  }

  .checkbox-container {
    width: 30%;
    text-align: right;

    &.double {
      @include flexbox();

      .c-1, .c-2 {
        width: 50%;
      }
    }
  }

  &.not-checked {
    color: color("grey", "base") !important;
    .s-icon, .info-icon {
      color: color("grey", "base") !important;
    }
  }

  &.with-checkboxes {
    display: block !important;

    .contents {
      @extend .vertical-align;
    }
  }
}

.parent-child-container {
  margin-bottom: 25px;

  .crown-icon {
    margin-right: 0.85714em;
  }

  .p-c-container {
    padding: 0 !important;
    &::before, &::after {
      display: none
    }
  }

  .check-container {
    @include flexbox();
    gap: 15px;
    margin-bottom: 5px;
  }
}

.ui.modal > .icon:first-child + *, .ui.modal > :first-child:not(.icon) {
  border-top-left-radius: $default-border-radius !important;
  border-top-right-radius: $default-border-radius !important;
}

.modals.dimmer .ui.modal {
  @include border-radius($default-border-radius !important);

  > :last-child {
    border-bottom-left-radius: $default-border-radius !important;
    border-bottom-right-radius: $default-border-radius !important;
  }

  .actions {
    .ui.button {
      font-size: 0.85714286rem;
    }
  }
}

.add-users-modal, .generic-modal, .publish-notice-modal {
  @extend .no-auth;
  padding: 0 !important;
  @include border-radius($default-border-radius !important);

  .button {
    // box-shadow: none !important;
  }

  .last-row-item {
    display: none !important;
  }

  .card-layout {
    max-width: none;
    position: relative;

    .segment {
      &.custom {
        @include border-radius($default-border-radius !important);
        box-shadow: none;
        overflow: hidden;
      }
    }

    .float-bottom {
      position: absolute;
      bottom: 10px;
    }

    .split-container {
      min-height: 500px;

      .left-side, .right-side {
        padding: 20px !important;
      }

      .left-side {
        // Overwrite the vertical align
        @include vertical-align(!important, $align: initial);
        background: $primary-color;

        &.publish-notice {
          background: $white;
          // align-items: center !important;
          outline: 1px solid rgba(34, 36, 38, 0.15);
        }
        
        .logo-container {

          .logo {
            width: 100px;
            height: auto;
          }
        }
      }

      .right-side {
        // Overwrite the vertical align
        @extend .no-margin;
        // @include vertical-align($align: initial);

        &.no-flex {
          display: block !important;
        }

        .button {
          &.transparent {
            box-shadow: none;
          }
        }

        .modal-buttons {
          width: 100%;
          text-align: right;
          margin-top: 20px;
          margin-bottom: 20px;
          position: absolute;
          right: 0;
          bottom: 0;

          .button {
            box-shadow: none;
          }
        }

        .modal-content {
          padding: 0;
          width: 100%;
          margin-bottom: 50px;
        }
      }
    }
  }

  .input-container {
    margin-bottom: 10px;
    
    small {
      &.description  {
        color: map.get($grey, 'base');
        margin-bottom: 5px;
      }
    }
	}

  .filters-section, .pagers-section {
    margin: auto !important;
  }
}

.crumbs-container {
  margin-bottom: 1rem;

  .breadcrumb {
    .active.section {
      &:last-child {
        font-size: 16px;
      }
    }
  }
}

span.label {
  cursor: default;
}

a, span {
  &.underline {
    text-decoration: underline !important;
  }
}

.graphs-container {
  .chart-grid {
    // border-bottom: 1px solid $bg-charts-gray;
    // margin-bottom: 20px;
    // padding-bottom: 20px;

    .initial-grid {
      padding-bottom: 15px;

      &.no-data-container {
        padding: 0;

        .no-data, h1 {
          height: 120px;
          @include vertical-align();
        }

        h1 {
          color: gray;
        }
      }
    }
    
    .chart-section-header {
      color: color('primary', 'darken-2');
    }

    .numeric-chart  {
      
      text-align: center;

      .display-value {
        margin: 0;
        font-size: 5em;
      }

      .label-text {
      }
    }

    .hr-right {
      border-right: 1px solid $bg-charts-gray;
    }
  }
}

.chart-container, .notice-recipients-container {
  // border-color: $primary-color !important;
  @include  border-radius(5px !important);
  margin-bottom: 15px !important;

  &.summary-chart {
    // @extend .rgba-grey-light;
    background-color: $bg-charts-gray !important;
    @extend .primary-color-dark-text;
    @extend .hr-white;
    @extend .no-border;
  }
  
  &.recipients-card {
    background-color: $bg-charts-gray !important;
    @extend .hr-white;
    @extend .no-border;
  }

  &.form-chart, &.no-divider {
    .title-container {
      border: none;
      text-align: center;
    }
  }

  .title-container {
    border-bottom: 2px solid $bg-charts-gray;
    padding: 10px;
    font-family: "Quicksand-Regular" !important;
  }

  .chart-legend {
    @include mq_medium_up() {
      font-size: small;
    }
  }

  .totals-caption {
    font-size: 2rem;
    font-family: "Quicksand-Bold" !important;
    @extend .trim-text;
    @include mq_medium_up() {
      font-size: 1.5rem;
    }
  }

  .totals-label {
    font-size: 80%;
  }
}

.users-container, .channel-labels {
  
  margin-top: 20px;

  .u {
    
    &-name {
      font-size: 1.5em;
    }

    &-name, &-number, &-email {
      margin: 20px 0;
      color: $white;
    }
  }

  .labels-container {
    margin-top: 5px;
    .ui.label {
      margin-bottom: 5px;

    }
  }
}

/* The device with borders */
.smartphone {
  position: relative;
  width: $smartphone-width;
  height: $smartphone-height;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone {
  position: relative;
  .s-content {
    // width: 360px;
    height: calc(#{$smartphone-height} - 120px);
    background: color('grey', 'lighten-4');
    overflow: scroll;
    @include scrollbars(0px, $primary-color);
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    zoom: $smartphone-zoom;
    // Disable interaction
    pointer-events: none;
    user-select: none;
  }

  .message-view {
    zoom: $smartphone-zoom;
    height: 100%;

    .header {
      background: $white;
      @extend .z-depth-2; 
    }

    .item.preview-list-item {
      
      &.file-component {
        margin-bottom: 1rem !important;
      }

      &.image-groups {

      }

      &:not(.file-component) {
        &:last-child {
          margin-bottom: 80px !important;
        }
      }
    }
  }
}

// Dropdown BGs to transparent
.ui.selection.dropdown {
  background: transparent !important;
}

.input-container {
  .ui.icon.input input, textarea {
    width: 100%;
  }
  
  textarea {
    @include border-radius(0.28571429rem);
    outline: 0;
    padding: .67857143em 1em;
    resize: none;
    width: 100% !important;
  }

  .PhoneInput {
    @include border-radius($default-tag-border-radius);
    border: 1px solid $input-border-color;
    background-color: transparent;
    width: 100%;

    .PhoneInputCountry {
      // margin-left: var(--PhoneInputCountrySelect-marginRight);
      margin-left: 10px;
    }
    
    .PhoneInputCountrySelectArrow {
      display: block;
      content: "";
      width: var(--PhoneInputCountrySelectArrow-width);
      height: var(--PhoneInputCountrySelectArrow-width);
      margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
      border-top: 0 solid black;
      border-bottom: 1px solid black;
      border-left: 0 solid black;
      border-right: 1px solid black;
      opacity: var(--PhoneInputCountrySelectArrow-opacity);
    }

    .PhoneInputInput {
      padding-left: 0px !important;
    }
    
    &.PhoneInput--focus {
      border-color: $secondary-color;
    }

    input {
      padding-left: 0;
      width: auto !important;
      border: none !important;
    }
  }

  .mini-input {
    width: 15%;
  }
}

// Image Component Preview
.image-component {
  border-radius: .3125em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;

  &.morphius {
    height: 80px !important;
    width: 80px;
    margin: 0 .25rem .5rem;
  }

  &.preview {
    height: 100px;
    background-color: $white;
    background-size: cover;

    &.empty {
      background-color: color('grey', 'lighten-1');
    }
    
    &.more-count {
      background-color: rgba(0, 0, 0, 0.5);
      background-blend-mode: color;
      display: flex;
      align-items: center;
      justify-content: center;

      small {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
    }
  }
}

.album-component {
  
  .column {
    
    &:nth-child(1) {
      &:not(:only-child) {
        padding-right: 0 !important;
      }
    }

    &:nth-child(2) {
      &:not(:last-child) {
        padding-right: 0 !important;
      }
    }

    &.small-thumbs {
      display: flex !important;
      flex-direction: column !important;
      gap: 10px;

      .chunk-container {
        display: flex;
        gap: 10px;

        &.row {
          flex-direction: column;
        }
      }

      .image-component.preview {
        height: 50px;
      }
    }
  }
}

.image-groups {
  padding: 0 !important;
  .column {
    box-shadow: none !important;
  }
}

.coming-soon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 80vh;
  justify-content: center;
}

.full-ui-block {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  
  .loader-container {
    @include vertical-align()
  }
}

.version-container {
  color: $black;
  position: absolute;
  // bottom: 112px;
  left: 7px;
  top: 17px;
  font-size: 0.9rem;
}

#notifications .beamer_icon.active {
  top: -10px !important;
  right: -10px !important;
}

#beamerSelector {
  background-color: $primary-color !important;
}

svg {
  
  &.icon {
    display: block;
    opacity: 1;
    /* margin: 0 .25rem 0 0 !important; */
    height: 1em;
    width: 1.18em;
    font-size: 20px;
  }
}

nav.pro-menu {
  &:not(.overflow-hide) {
    // padding: 0 0 2px 0 !important;
    padding: 0 !important;
  }

  &.overflow-hide {
    
    padding: 30px 0 30px 0;

    [aria-live="polite"]  {
      display: none;
    }
  }
}

.ui.loader, .ui.loading, .ui.loading i  {
  
  &:after {
    border-color: $primary-color transparent transparent !important;
  }
}

.button {
  &.secondary {
    &.disabled {
      background-color: transparent  !important;
      box-shadow: 0 0 0 1px $primary-color inset !important;
    }
  }
  
  &.primary {
    &.disabled {
      background-color: color('grey', 'lighten-1') !important;
    }
  }
}

.default-content-area {
  
  .white-bg {
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    @include border-radius(5px);
    background-color: $white;
  }

  > div {
    // width: 95%;
    margin: 0 auto;
    // padding: 30px;
    @include border-radius(5px);
  }
}

.detail-actions {
  width: 100px;
  float: right;
}

.create-calendar-container {
  .card-layout {
    max-width: 68rem;
  }
}

.create-channel-container, .create-calendar-container, .import-member-container {

  .ui.segments.main-container {
    box-shadow: none;
    border: none;
    
    .button-choice {
      display: flex;
      gap: 10px;
      @extend .vertical-align;
      justify-content: center;
      text-transform: none !important;

      .image {
        height: 31px !important;
      }

      &.no-action {
        opacity: 0.8 !important;
        color: #eeeeee !important;
        background-color: #c7c7c7 !important;
        cursor: default;
      }
    }

    .ui.buttons .or {
      height: calc(31px + 1.14285714rem + 0.785714em);
      &::before {
        background: color("primary", "base");
        color: white;
      }
    }

    .calendars-list {
      max-height: 400px;
      overflow-y: scroll;

      .ui.segment {
        display: flex !important;

        &.active {
          background-color: color("secondary", "lighten-4");
        }

        .content {
          gap: 10px;
          @extend .vertical-align;
        }

        &:hover {
          cursor: pointer;
          background-color: #f3f4f5;
        }
      }
    }

    .steps-contents {
      box-shadow: none;
      border: none;
    }
    
    .ui.segment.seg-steps {
      padding: 30px;

      &.calendar-steps {
        background-color: color('primary', 'base')
      }

      .ui.steps {
        .step {
          @include justify-content(left);

          .description, .title {
            color: color('grey', 'lighten-1');
          }

          .icon {
            font-size: 25px;
            margin: 0 0 0 1rem;
          }

          &.completed {
            .description, .title {
              color: $default-text-color;
            }
          }

          &.active {
            .title {
              color: $primary-color;
            }

            .description {
              color: $default-text-color;
            }
          }
        }
      }
    }
  }

  .ui.accordion {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
  }

  .content-header {
    display: flex;
    align-items: center;
  }

  .content-section {
    padding: 20px !important;

    &.center-align {
      justify-content: center !important;
    }

    .message {
      background-color: #dff0ff;
      text-align: left;
      
      .icon {
        color: color('primary', 'base');
      }
      // box-shadow: 0 0 0 1px color('primary', 'base') inset, 0 0 0 0 transparent;
      box-shadow: none;
    }
  }
}

.calendar {

  padding-top: 24px !important;
  padding: 0 !important;

  b {
    margin-right: 5px;
  }

  .default-content-area { 
    > div {
      width: 100%;
      margin: 0;
      border-radius: none;
    }
  }

  .calendar-section {
    display: flex;
    min-height: 100%;
    font-size: 14px;
  }
  
  .calendar-section-sidebar {
    width: 300px;
    line-height: 1.5;
    background: white;
    border-right: 1px solid $nav-border-color;
  }
  
  .calendar-section-sidebar-section {
    padding: 2em;
  }
  
  .calendar-section-main {
    flex-grow: 1;
    padding: 3em;
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  
}

.breadcrumb {
  .active {
    color: $default-text-color !important;
  }
}

#beamerSelector {
  display: none;
}

a.announcekit-widget-badge {
  position: absolute !important;
  top: -10px;
  left: 10px;
}

.ui.page.modals.transition {
  // Dissable  this for now
  // z-index: 2247483646;
}

.help-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.experimental-ui-banner {
  text-align: center;
  margin-bottom: 20px;
}

.login-callback {
    
  .container {
      height: 100%;
      text-align: center;
      color: white;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
  }

  .container h1 {
      width: 100%;
      margin: 0;
  }

  .logo-container {
      margin: 0 auto;
  }

  .logo-container img  {
      width: 300px;
  }
}

h3, h4 {
  &.page-caption {
    .icon.primary-color-text {
      margin-right: 15px;
    }
  }
}

.channel-detail {
  .ui.segments.main-container .ui.segment {
    padding: 25px;
  }
}

#chartjs-tooltip {

  table {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 5px;
  }

  .chartjs-tooltip-key {
    border-width: 2px;
    height: 10px;
    width: 10px;
    margin-right: 5px;
    font-family: 'Quicksand-Thin';
  }
}

.info-icon {

  &.community-roles, &.create-channel {
    cursor: pointer;
    color: $primary-color;
    margin-left: 10px;

    &:hover {
      color: $secondary-color;
    }
  }
}

.has-no-content {
  display: none;
}

.ui.breadcrumb a {
  color: $primary-color;
}

img.logo-holder {
  width: 30px !important;
  height: 30px;
  border-radius: 20%;
  object-fit: scale-down; 
  -o-object-fit: scale-down;
}

.default-content-area {
  > .page {

    .page-caption, .crumbs-container {
      margin-bottom: 1rem;
      font-size: 14px !important;

      &.with-limit-indicators {
        display: flex;
        gap: 25px;
        align-items: center;
        
        h1 {
          font-size: 14px !important;
          margin: 0;
        }

        .indicators {
          flex: 1;

          p {
            text-align: right;
          }

          .ui.negative.message {
            p {
              text-align: left;
            }
          }

          .description {
            color: color('grey', 'base');
          }

          .icon {
            
            margin-right: 10px;

            &._warning {
              color: color('orange', 'lighten-1');
            }
            
            &.healthy {
              color: color('green', 'lighten-1');
            }
          }
        }
      }
    }

    .page-sub-caption {
      padding: 15px;
    }

    .grey-hr {
      padding-bottom: 15px;
      border-bottom: 1px solid color('grey', 'lighten-3');
    }

    .header-container {
      // width: 75%;
      margin: 0 auto;
      position: relative;

      .update-cover-button {
        position: absolute;
        left: 15px;
        bottom: 25px;
      }

      .update-profile-button {
        margin: 0;
        position: absolute;
        right: -11px;
        bottom: -20px;
        background-color: $white;
        color: $primary-color;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
        
        &:hover {
          color: $secondary-color;
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
        }
      }

      .buttons-gradient {
        background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6));
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100%;
        z-index: 1;
      }

      .cover-image {
          background-image: url(https://www.curro.co.za/media/q0npfve4/52.jpg);
          background-color: color.adjust($primary-color, $lightness: 35%);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
          @include border-top-radius(5px);
          position: relative;
      
          .profile-image {
              background-image: url(https://d6.co.za/wp-content/uploads/2020/08/d6-1.png);
              background-position: center;
              background-color: $white;
              background-repeat: no-repeat;
              background-size: cover;
              height: 150px;
              width: 150px;
              border: 5px solid white;
              position: absolute;
              bottom: -50px;
              left: calc(88% - 150px/2);
              @include border-radius(5px);
              @extend .z-depth-1;
              z-index: 2;
          }
      }
      
      .username-text {
          padding-top: 20px;
          font-family: inherit;
  
          span {
              font-weight: normal;
              font-family: inherit;
          }
      }
  
      .bio-text {
          color: #009fff;
          font-weight: bold;
          font-family: inherit;
      }
    }
  }
}

.page-section {
  margin-bottom: 30px !important;
  padding: 15px !important;
  
  &.no-padding {
    padding: 0 !important;
  }

  &.with-cover {
    padding: 0 !important;

    .page-content {
      padding: 15px;
    }
  }

  .section-description {
    margin-bottom: 20px;
  }

  .section-header {
    .icon {
      margin-right: 10px;
    }
  }

  .pagination-container, .filters-container {
    margin-top: 0px !important;
  }
}

.billing-content-area {
  .primary-bg {
    background-color: $primary-color !important;

    .header, .content, .meta, .description {
      color: $white !important;
    }
  }

  .default {
    .icon {
      color: $primary-color;
    }
  }
  
  .br-grey {
    border-color: color('grey', 'lighten-1') !important;
  }

  .plan-name {
    font-family: "Quicksand-Regular";
    font-size: 1.2em;
    margin-top: 15px !important;
  }

  h1 {
    small {
      font-family: "Quicksand-Regular";
      font-size: small;
    }
  }

  .transactions-table-container {
    padding: 15px;
  }
}

.ui.form .error.message, .ui.form .success.message, .ui.form .warning.message {
  margin-top: 15px !important;
  margin-bottom: 30px !important;

  &.tier-warning, &.tier-limit-reached {
    display: block;
    margin-bottom: 0px !important;

    background-color: #ffedde !important;
    color: #f2711c !important;
    box-shadow: 0 0 0 1px #f2711c inset, 0 0 0 0 transparent !important;
  }
}

.has-errors {
  $error-color: color('red', 'lighten-5') !default;

  // border: 1px solid $error-color;
  // border-left: 5px solid color("red", "base");
  @include hr-line-indicator(color("red", "base")  !important);
  @include hr-line-indicator-activate(color("red", "base") !important);

  .notice-title-input-container {
    .input > input {
        border-bottom: 0.5px solid $error-color !important;
    }
  }
}

.has-complete {
  border: 1px solid color('green', 'lighten-1');
}

.react-datepicker__input-container > input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid $input-border-color;
  color: rgba(0, 0, 0, 0.87);
  border-radius: .28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}

.react-datepicker-wrapper {
  .ui.button {
    
    font-weight: normal;

    &.el-picker {

      background-color: transparent !important;
      color: $input-text-color !important;
      box-shadow: 0 0 0 1px $input-border-color inset !important;
  
      @include hover {
        box-shadow: 0 0 0 1px color('grey', 'darken-1') inset !important;
        color: color('grey', 'darken-1') !important;
      }
    }

    position: relative;
    width: 100%;
    text-align: left;
    padding-left: 15px;
    
    .icon {
      position: absolute;
      right: 10px;
      color: color('primary', 'base') !important;
    }
  }
}

.channels-selection {
  padding-top: 10px;
  padding-bottom: 10px;

  .label {
    cursor: pointer;
    margin-bottom: 5px;
    @include border-radius($default-tag-border-radius !important);
    &.active {
      background: $primary-color !important;
      height: 30px;
      color: $white !important;
      line-height: inherit;
    }
  }
}

.contact-multi-input {
  .field {
    width: 100%;
    &:last-child {
      margin-bottom: 1em !important;
    }
    
    &:first-child {
      margin-bottom: 1em !important;
    }
  }

  .button.icon {
    margin: 0 0 1em;
    margin-left: 5px;
  }

  @include vertical-align();
}

.contact {
  &-add-field-button {
    width: 30%;
  }
}

.error {
  &-label {
    color: #9f3a38;

    &.small {
      font-size: 12px;
      margin: 2px;
    }
  }
}

.place-picker {
  #react-select-3-placeholder + [data-value], #react-select-2-placeholder + [data-value], [data-value] {
    display: flex;
  }
  span, svg {
    display: none !important;
  }
}

//NOTE: Temporary solution to only show time using the date picker
.date-picker {
  &.time-only {
    .react-datepicker__month-container, .react-datepicker__triangle, .react-datepicker__navigation {
      display: none !important;
    }
  }
}


.profile {
  .disabled-profile-edit {
    position: relative;
    cursor: pointer;

    * {
      @extend .no-select-disabled;
    }

    span.edit-icon {
      display: none;
      position: absolute;
    }

    &:hover {
      span.edit-icon {
        display: initial;
        right: 0;
        color: $secondary-color;
      }
    }
  }
}


.data-init-screen {
  @extend .no-auth;
  background-color: $white !important;
  .ui.inverted.loader {
    color: $default-text-color !important;
  }
}

.builder-section-sender-details {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .white-bg-container {
    padding: 20px 35px !important;
    
    @include hr-line-indicator(color("primary", "base"));

    &:focus-within {
      @include hr-line-indicator-activate(color("primary", "base"));
    }
  }

}

.quickflow {
  
  &-dimmer {
    background-color: rgba(0, 0, 0, .93) !important;
  }

  .content {
    background-color: $bg-color !important;
    margin: 0;

    .white-bg-container {
      margin: 0;
    }
    .drop-drop-zone {
      // margin-left: 15px;
      // margin-right: 15px;
      .column.pad-bot-15 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .components-container {
      width: auto;
    }

    .transformed-content-container {
      .drag-indicator {
        display: none !important;
      }
    }
  }

  .icons-container {
    .icon.buttons {
      .ui.button {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
}

.components-container {
  width: 100%;

  .secondary-list {
    display: flex !important;
    opacity: 1;
    transition: opacity 0.5s, visibility 0.5s;
    display: inherit;
    // margin-top: 10px !important;

    &.hidden {
      opacity: 0;
      transition: opacity 0.5s, visibility 0.5s;
      display: none !important;
    }

  }

  .component-droppable {
    $component-margs: 0.4rem;

    display: flex;
    padding-top: $component-margs !important;
    padding-bottom: $component-margs !important;

    .draggable-component {
      padding: 0 !important;
      padding-right: 15px !important;
      width: fit-content !important;
      margin-bottom: 10px !important;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;

      &.hidden {
        opacity: 0;
        display: none;
        visibility: hidden;
      }

      &.visible {
        // padding-top: $component-margs !important;
      }
      
      &.component-toggler {
        position: absolute;
        top: $component-margs;
        padding: 0 !important;
        right: 0;

        .template-info {
          padding-left: 10px;
          padding-right: 10px;
        }

        .template {
          &:hover {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.notice-detail-actions {
  &.menu {
    right: 10% !important;
    left: inherit !important;
    top: 109% !important;
  }
}

.coming-soon {
  position: relative;
  .page-caption {
    position: absolute;
    top: 5rem;
    font-size: xx-large;
  }
}

.error-explanation {
  .error-message {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .s-callback {
    text-align: left;

    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    
    .zone-1 {
      margin-bottom: 10px;
      p {
        font-size: 16px;
      }
    }

    .zone-2 {
      display: flex;
      justify-content: center;
      flex-direction: column;

      ol {
        padding-inline-start: 15px;
      }
    }
  }

  .full-error-explanation {
    background: color('grey', 'lighten-4');
    padding: 10px;
    border-radius: 5px;
  }
}

.rive-loader.error {
  canvas, img {
    width: 100px !important;
    height: auto !important;
  }
}  
.send-message-button {
  .dropdown.icon {
    border-left: 1px solid color('grey', 'lighten-1');
  }
}

.dropdown-with-selected-icon {
      
  position: relative;

  .text {
    &[role=alert] {
      margin-left: 30px !important;
    }
  }

  .image.source {
    position: absolute;
    top: calc(50% - 10px);
  }
}

.general-loader {
        
  position: absolute;
  top: 3.07142857rem;

  &.loader {
      &::before, &::after {
        display: none;
      }
  }

  .rive-loader, .loader-image {
      canvas {
          width: 100px !important;
          height: 50px !important;
      }
      img {
          width: 50px !important;
          height: auto !important;
      }
  }
}

.reminder-container {
  .message {
    box-shadow: none;
    // border: 1px color('primary', 'lighten-4') solid;
    background: color('primary', 'lighten-3');
    color: $black;
  }
}

.event {

  &-icon {
    margin: 0;
    font-size: 1.2rem !important;
  }

  &-dates, &-location {
    vertical-align: top;
    margin: 0;
    font-size: 1.5rem;
    font-family: 'Quicksand-SemiBold';
  }

  &-detail {
    .content-grid {
      display: grid;
      gap: 10px;
      grid-template-columns: 30px auto;
    }
    
    .icon-container {
      display: flex;
      align-items: center;
    }

    .content-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .notes {
      background: color('grey', 'lighten-3');
      padding: 20px;
      @include border-radius(5px);
    }

    &.mini {
      margin-bottom: 0 !important;
      padding: 0 !important;
      .event-dates, .event-location {
        font-size: 1rem;
      }
    }
  }


}

.fc {
  .fc-popover {
    max-width: 20rem;
  }
  
  .fc-daygrid-dot-event .fc-event-title {
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: nowrap;
  }
  
  
  .fc-button-primary, .fc-button-group {
    button {
      text-transform: capitalize;
      
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.calendar-page {
  .modals.dimmer {
    z-index: 9999 !important;
  }
}

.dropzone {
  --default-dz-border-color: #eeeeee;
  --default-dz-bg-color: #fafafa;
  --default-dz-text-color: #bdbdbd;
  
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: var(--default-dz-border-color);
  border-style: dashed;
  background-color: var(--default-dz-bg-color);
  color: var(--default-dz-text-color);
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  .small-text {
    color: var(--default-dz-text-color);
  }

  &.error {
    --default-dz-border-color: #9f3a38;
    --default-dz-bg-color: rgb(255 205 210 / 30%);
    --default-dz-text-color: #9f3a38;

    color: var(--default-dz-text-color);
    border-color: var(--default-dz-border-color);
    background-color: var(--default-dz-bg-color);

    .small-text {
      color: var(--default-dz-text-color);
    }

    .button.secondary {
      color: var(--default-dz-text-color) !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px var(--default-dz-text-color) inset !important;
    }
  }
  
  &.incoming {
    --default-dz-border-color: #{color('secondary', 'base')};
    --default-dz-bg-color: #{color('secondary', 'lighten-3')};
    --default-dz-text-color: #{color('secondary', 'base')};

    color: var(--default-dz-text-color);
    border-color: var(--default-dz-border-color);
    background-color: var(--default-dz-bg-color);

    .small-text {
      color: var(--default-dz-text-color);
    }

    .button.secondary {
      color: var(--default-dz-text-color) !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 1px var(--default-dz-text-color) inset !important;
    }
  }

  &.import {
    position: relative;
    height: 200px;
    justify-content: center;
    cursor: default;

    .selected-file {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      small {
        color: color('grey', 'base');
      }

      .action-buttons {
        position: absolute;
        background: white;
        right: 0.5rem;
        top: 0.5rem;
      }
    }
  }
}

.selection-indicator {
  padding: 0.78571429em 1.5em 0.78571429em !important;
  
  &.large {
    --font-size: 1.14285714rem;
    // font-size: var(--font-size) !important;
    margin-bottom: calc(0.685714em - var(--font-size)/2);
  }

  border-radius: 21px !important;
  text-transform: capitalize !important;
  background-color: color("secondary", "base") !important;
}

.import-dropdown-menu {
  width: max-content;
}

.adaptive-icon {
  &:not(.preview) {
    font-size: 4em !important;
  }
}

.flowing-constrained {
  max-width: 100vw !important;
}

.dimmer {
  &.imports, &.general {
    .content {
      position: relative;
    }
    
    .table-loader-container {
      @extend .no-padding;
      top: 0 !important;
    }
  }

  &.general .loader {
    &::before, &::after {
      display: none;
    }
  }
  
}

.flex-container {
  display: flex;
  justify-content: right;
  gap: 10px;
  
  .__icon-container {
    @extend .vertical-align;
    font-family: "Quicksand-Bold";
    
    strong {
      color: color('primary', 'base');
    }

    .has-app {
      strong {
        color: color('green', 'base');
      }
    }
  }
}

.ui-checkbox-container {
  text-align: left;
  padding-top: 15px;
}

.notice-detail-loading {
  min-height: 100px;
  width: 100%; 
  position: relative;

  .ui.inverted.dimmer .ui.loader, .ui.loader {
    min-width: 200px;
  }
}

.flexed-items-container {
  display: flex !important;
  align-items: center;
  gap: 10px;

  .mw-30 {
    max-width: 30%;
  }
}

.fc-media-screen:has(.fc-calendar-hidden) {
  .fc-view-harness { 
    display: none !important;
  }

  .fc-button-group {

    button.fc-dayGridMonth-button, button.fc-timeGridWeek-button, button.fc-timeGridDay-button {
      color: var(--fc-button-text-color, #fff);
      background-color: var(--fc-button-active-bg-color, #1a252f);
      border-color: var(--fc-button-active-border-color, #151e27);
      pointer-events: none;
    }
  }
}

.search-dropdown {
  padding: 0 !important;
  width: 100%;

  &.current-selection {
    @extend .no-select-disabled;
    min-height: 40px !important;
    position: relative;

    .clear-selection {
      position: absolute;
      right: -10px;
      top: -10px;
    }

    .visible.menu.transition {
      &:empty {
        display: none !important;
      }
    }

    .menu {
      width: 100% !important;
      position: relative !important;
      box-shadow: none !important;
      z-index: 1 !important;
    }
  }

  .menu {
    width: 100%;
    min-width: auto !important;
    
    &.scrolling {
      max-height: none !important;
    }

    .item.search-result-item {
      display: flex !important;
      gap: 1rem;
      
      &:has(.no-results), :has(.more-results) {
        cursor: default;
      }

      img {
        margin: 0 !important;
        height: 20px;
        width: 20px;
      }

      .text {
        display: grid;
        grid-gap: 1rem;
        gap: 1rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 100%;

        &.no-results {
          grid-template-columns: calc(60% - 1rem) calc(40% - 1rem);
          
          .seg-1 {
            .title {
              @extend .trim-text;
              width: 100%;
            }
          }

          .seg-2 {
            align-items: end;
            width: 100%;

            span {
              color: color('primary', 'base');
              cursor: pointer;
            }
          }
        }

        .seg {
          display: flex;
          flex-direction: column;
          
          a,span {
            font-size: 12px;
          }
          
          span {
            color: color('grey', 'base');
          }

          .location {
            width: 100%;
            @extend .trim-text;
          }
          
          .title {
            font-size: 15px;
            color: #1A252F;
          }

          .day-container {
            font-size: 13px;
            color: #1A252F;
          }

          &-1 {
            align-items: start;
          }

          &-2 {
            align-items: end;
          }
        }
      }
    }
  }
}

.link-calendar-container {
  max-height: 300px;
  margin-top: 25px;
  overflow-y: scroll;

  &.no-scroll {
    overflow-y: auto;
  }

  .list-header {
    background-color: color('grey', 'lighten-3') !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 100;

    &.has-error {
      background-color: #fff6f6 !important;
      h3 {
        color: #9f3a38 !important;
      }
    }
  }

  .list-item {

    padding: 0 !important;

    &.active {
      background-color: color("secondary", "lighten-4");
    }

    .ui.image.rounded {
      max-height: 2rem;
    }

    label.content {
      padding: 1em 1em;
    }

    .content {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &:hover {
      cursor: pointer;
      background-color: #f3f4f5;
    }
  }
}

.calendar-buttons-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background-color: color('primary', 'lighten-3');
  @include border-radius(0.5rem);

  div:nth-child(1) {
    flex: 61% 1;
  }

  button {
    color: $white;
    background-color: white !important;
    font-size: 0.85714286rem;

    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    }
  }

  p:first-child {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Quicksand-Bold';
  }
  
  p:nth-child(2) {
    font-size: 1rem;
    font-family: 'Quicksand-Regular';
  }
}

.ui.form  .required.field label {
  &::after {
    margin: -0.2em 0 0 0.2em;
    content: "*";
    color: #db2828;
  }
}

.event-detail-row {

  &.row, .row {
    gap: 5px;
  }

  > div.column:first-child {
    flex: 0 1 10% !important;
    text-align: center;
  }

  div {
    flex-grow: 1;
    
    &.no-grow {
      flex-grow: 0;
    }
    
    .center-items {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.grow-sm {
      flex-grow: 0.1;
      text-align: center;
    }
  }
}

.notices-container {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(1fr, 2);

  .ui.label:hover {
    * {
      color: color('secondary', 'base');
    }
  }
}

.source-label {
  display: flex !important;
  gap: 0.5rem;
  align-items: center;

  background-color: color('grey', 'lighten-3');
  padding: 0.3rem 1rem;
  @include border-radius(15px);

  img {
    height: 1rem !important;
  }
}

.repeat-container {
  display: flex !important;
  gap: 0.5rem;
  align-items: center;
}

.create-event-modal {
  .ui.grid > .row {
    &:not(:first-child) {
      padding-top: 0 !important;
    }
  }
}

th.fc-col-header-cell.fc-day {
  padding: 10px;
}

.fc-day-today .fc-col-header-cell-cushion {
  background-color: color('secondary', 'base');
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
}

.sync-indicator.label {
  &.primary.transparent {
    cursor: default;
    color: color('primary', 'base');
  }
}

.sync-all-button.button {
  color: color('primary', 'base') !important;
  &:hover {
    color: color('secondary', 'base') !important;
  }
}

.labels-container.event-detail {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.calendar-provider-buttons {
  max-width: 30rem;
}

.link-calendar-full-page {
  display: flex;
  align-items: center;
  .calendar-items-container {
    max-width: 45rem;
  }
}

.event-delete-methods-container {
  margin-top: 10px;
  padding-left: 20px;
}

.link-calendar {
  background: $white !important;
  // border: 1px solid !important;
}

.virtualised-menu {
  min-height: 16.02857143rem;
  [role=option].item {
    border-top: 1px solid #fafafa;
    padding: 0.78571429rem 1.14285714rem !important;
    white-space: normal;
    word-wrap: normal;
  }
}

.tiering-plan-menu {
  border-color: #bdbdbd #bdbdbd #bdbdbd !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;

  &.ui.menu .item {
    gap: 10px;
  }

  .button {
    font-size: 0.78571429rem !important;
  }
}

.upgrade-required {
  @extend .no-select;

  * {
    color: color("grey", "base") !important;
  }

  .ui.checkbox {
    opacity: .45;
  }
}

.t-upgrade-required, .t-addon-required {

  --template-border-color: #{color("secondary", "base")};
  // --template-bg-color: #{color("secondary", "lighten-3")};
  
  // .template-info {
  //   .icon {
  //     color: var(--template-border-color) !important;
  //   }
  // }

  &.disabled.item.override {
    pointer-events: all !important;
    // cursor: pointer !important;
    opacity: 1 !important;

    &.pad-icon .text {
      margin-right: 1rem;
    }

    .text {
      opacity: .45;
      pointer-events: none;
    }
  }

  &.ui.label {
    --template-bg-color: #{color("secondary", "lighten-3")};
    --template-border-color: #{color("secondary", "darken-2")};
    color: var(--template-border-color);
    background-color: var(--template-bg-color);
    margin-left: 10px;
  }

  &.ui.ribbon {
    .crown-icon {
      position: relative;
      top: unset;
      right: unset;
    }

    display: flex;
    // gap: 2px;
    align-items: center;
  }

  .crown-icon, &.crown-icon {
    position: absolute;
    right: 10px;
    top: -8px;
    color: var(--template-border-color) !important;
    font-size: 15px;

    &.in-dropdown {
      top: auto;
    }
  }
}

.fc-daygrid-event {
  cursor: pointer;
}

.disabled-actionable {
  opacity: .45;
}


.recurrence-rule-modal {
  .days-container {
    .flex {
      
    }
  }
}

.disabled-actionable {
  opacity: .45;
}

.ui.accordion .title:not(.ui) {
  width: fit-content;
}

.assisted-writing-modal {
  .header {
    border: 0 !important;
  }
  .content {
    min-height: 20rem;
    padding-top: 0 !important;
  }

  .warning-label {
    // background-color: color('secondary', 'lighten-4') !important;
    background-color: lighten(color('secondary', 'base'), 35%) !important;
    color: color('secondary', 'darken-3') !important;
  }

  .input-container {
    height: 100%;
  }

  .error-container {
    --default-error-border-color: #9f3a38;
    --default-error-bg-color: rgb(255 205 210 / 30%);
    --default-error-text-color: #9f3a38;
    color: var(--default-error-text-color);
    border-color: var(--default-error-border-color);
    background-color: var(--default-error-bg-color);
    border-radius: $default-tag-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 5px;
    flex-direction: column;
    text-align: center;
  }

  textarea {
    padding: 1rem;
    background-color: color('grey', 'lighten-3') !important;
    border: none;
    border-radius: $default-tag-border-radius;
  }

  .ui.inverted.dimmer {
    background-color: $white !important;
  }
}

.preview-bg {
  background-color: $bg-color !important;
}

.channel-member-mini-detail {
  border: 0 !important;
  
  * {
    border: 0 !important;
  }

  tr td:first-child {
    color: $black;
    font-weight: bold;
    font-family: 'Quicksand-SemiBold';
  }

  .ui.label.color-primary-light {
    margin: 0.14285714em;
  }
}

.lightbox {
  .yarl__slide {
    .item.preview-list-item, .video-container {
      height: 100%;
      width: 100%;
    }
  }

  .yarl__thumbnails_thumbnail {
    .youtube-placeholder {
      @extend .red-text;
      .icon {
        margin: 0 !important;
      }
    }
  }
}



.recipient-hover-popup {
  overflow-y: auto;
  max-height: 400px;
  // min-width: 600px;
}

.recipient-hover-items {

  .divide {
    border-bottom: 1px solid $bg-charts-gray;
  }

  .form- {
    &text-item, &bool-item {
      padding: 5px;
      background-color: $bg-charts-gray !important;
      @include border-radius(5px);
    }
    
    &multiple-item {
      padding-left: 5px;
    }

    &file-item {
      .adaptive-icon {
        font-size: 3em !important;
      }
      
      padding: 5px;
      background-color: $bg-charts-gray !important;
      width: max-content;
      @include border-radius(5px);
    }
    
    &image-item {
      display: flex;
      flex-direction: column;
      .image {
        margin: 0 !important;
        height: 80px !important;
        width: auto;
        padding: 5px;
        background-color: $bg-charts-gray !important;
        width: max-content;
        @include border-radius(5px);
      }
    }
  }
  
  .payment- {
    &item {
      padding: 5px;
      background-color: $bg-charts-gray !important;
      @include border-radius(5px);
    }
  }
}

.no-targets {
  &.item {
    padding: 1.78571429rem 1.14285714rem !important;
  }
}

.notice-details {
  &.labels-container {
    @include vertical-align();
    gap: 5px;
  }
}

.show-on-lock {
  display: none !important
}

.default-content-area .page.is-locked, .page .is-locked {

  .input-container.theme-color {
    .label {
      @extend .no-select;
    }
    .input, .checkbox, .dropdown {
      @extend .no-select;
      @extend .opacity-60;
    }
  }

  .checkbox-container, .checkbox, button.ui.button.primary {
    @extend .no-select;
    @extend .opacity-60;
  }

  .disable-on-locked {
    @extend .no-select;
    @extend .opacity-60;
  }
}

.locked-message {
  @extend .color-primary-light;
  @extend .no-border;
  @extend .no-box-shadow;

  color: color('primary', 'darken-2') !important;
}

.resource-is-locked {
  .hide-on-lock {
    display: none;
  }
  .show-on-lock {
    display: inherit !important;
  }
  .disable-on-locked {
    @extend .no-select;
    @extend .opacity-60;
  }
}

.locked-message {
  @extend .color-primary-light;
  @extend .no-border;
  @extend .no-box-shadow;

  color: color('primary', 'darken-2') !important;
}

.loader {
  .loader-image {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.create-manual-payment-modal {
  > .content {
    background: $preview-bg-color !important;
  }

  .m-content.preview-list {
    width: 100%;
  }

  .itemised-controls .controls-row-container {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .preview-list-item.payment-item .title {
    font-size: medium;
  }

  .add-to-list-checkbox {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    
    .total-container {
      flex: auto;
      display: flex;
      text-align: start;
      gap: 0.3rem;
      color: #777777;
      align-items: center;
    }
  }
}

.segment-bg-container {
  background: $bg-color;
  @include border-radius($default-border-radius);
  border: none;
  width: 90%;
  margin: 0 auto;
}

.payment-items {
  border-top: 1px solid color("grey", "lighten-2");
}

.reversal-title-container {
  display: flex !important;
  gap: 0.5rem !important;
  
  :first-child {
    flex: 1;
  }

  .payment-reversal-container {
    cursor: pointer;
    width: auto;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: end;

    span {
      font-weight: bold;
      text-align: end;
      font-family: 'Quicksand-Bold';
    }
  }
}

.message-view.reversal .preview-list .preview-list-item {
  outline: 0.1rem solid $secondary-color;
}

// DEBUG LAYERS
// TRAINING WHEELS
// div.column {
//   border: 0.1px solid red;
// }
// table {
//   td , th {
//     outline: 0.1px solid red;
//   }
// }