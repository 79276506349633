$timeline-dot-color: $primary-color !default;
$timeline-line-color: $primary-color !default;
$timeline-dot-start-color: color('grey', 'base') !default;
$timeline-dot-size: 10px !default;
$timeline-line-width: 1px !default;
$timeline-dot-left-padding: -(calc($timeline-dot-size/2) + calc($timeline-line-width/2)); // We need to do this so we can place the dot smack band center of the line

.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: $timeline-line-width;
    margin-left: -1.5px;
    content: "";
    background-color: $timeline-line-color;
}

.timeline > li {
    position: relative;
    // min-height: 50px;
    margin-bottom: 50px;

    &.now {
        color: $timeline-dot-start-color;
        
        .timeline-image {
            border-color: $timeline-dot-start-color;
            background-color: $timeline-dot-start-color;
        }

        &:after {
            display: none;
        }
    }
}

.timeline > li:after, .timeline > li:before {
    display: table;
    content: " ";
}

.timeline > li:after {
    clear: both;
}

.timeline > li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0px;
    text-align: left;
}

.timeline > li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline > li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline > li .timeline-image {
    position: absolute;
    z-index: 100;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    text-align: center;
    color: white;
    border: 1px solid $timeline-dot-color;
    border-radius: 100%;
    background-color: $timeline-dot-color;
}

.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline > li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
    margin-top: 0;
    color: inherit;
}

.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
    text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
    margin-bottom: 0;
}

.timeline:before {
    left: 50%;
}

.timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0;
    text-align: right;

    &.right {
        float: right;
        text-align: left;
    }
}

.timeline > li .timeline-image {
    left: 50%;
    width: $timeline-dot-size;
    height: $timeline-dot-size;
    margin-left: $timeline-dot-left-padding;
}

.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0;
    text-align: left;
}

.message-container {
    box-shadow: none !important;
    border: 1px solid $primary-color !important;
}